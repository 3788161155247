import produce from 'immer';
import { GmailLabel } from 'protos/automation_mining/ontology/data_models';
import { Reducer } from 'redux';
import { UtilityActionType } from '../actions/actions.constants';
import { ScheduleTask } from 'protos/pb/v1alpha2/utility_service';
import { DataLoadingStatus } from '../../utils/constants';

export interface UtilityState {
  tasks: ScheduleTask[];
  loadingTasksStatus: DataLoadingStatus;
  tasksError?: Error;
  gmailLabels: GmailLabel[];
  loadingGmailLabelsStatus: DataLoadingStatus;
  getGmailLabelsError?: Error;
}

const initialState: UtilityState = {
  tasks: [],
  loadingTasksStatus: DataLoadingStatus.INITIAL,
  gmailLabels: [],
  loadingGmailLabelsStatus: DataLoadingStatus.INITIAL,
};

export const utilityReducer: Reducer<UtilityState> = (
  state: UtilityState = initialState,
  action: any,
) => {
  return produce(state, (draft: UtilityState) => {
    switch (action.type) {
      case UtilityActionType.GET_GMAIL_LABELS:
        draft.loadingGmailLabelsStatus = DataLoadingStatus.LOADING;
        break;
      case UtilityActionType.GET_GMAIL_LABELS_COMPLETED:
        draft.gmailLabels = action.payload;
        draft.loadingGmailLabelsStatus = DataLoadingStatus.LOADED;
        draft.getGmailLabelsError = undefined;
        break;
      case UtilityActionType.GET_GMAIL_LABELS_ERROR:
        draft.loadingGmailLabelsStatus = DataLoadingStatus.ERROR;
        draft.getGmailLabelsError = action.payload;
        break;
      case UtilityActionType.GET_SCHEDULED_TASKS:
        draft.loadingTasksStatus = DataLoadingStatus.LOADING;
        break;
      case UtilityActionType.GET_SCHEDULED_TASKS_COMPLETED:
        draft.tasks = action.payload;
        draft.loadingTasksStatus = DataLoadingStatus.LOADED;
        draft.tasksError = undefined;
        break;
      case UtilityActionType.GET_SCHEDULED_TASKS_ERROR:
        draft.loadingTasksStatus = DataLoadingStatus.ERROR;
        draft.tasksError = action.payload;
        break;
      default:
        break;
    }
  });
};
