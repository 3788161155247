import produce from 'immer';
import { Workflow, WorkflowTask } from 'protos/pb/v1alpha1/orbot_workflow';
import { Reducer } from 'redux';
import { WorkflowTaskActionType as WorkflowStateActionType } from '../../redux/actions/actions.constants';
import { DataLoadingStatus } from '../../utils/constants';

export interface WorkflowWithTask {
  task?: WorkflowTask;
  workflow?: Workflow;
  error?: Error;
}

export interface WorkflowTaskState {
  workflowTaskData?: WorkflowTask;
  workflowTaskError?: Error | null;
  workflowData?: Workflow;
  workflowError?: Error | null;
  loadingStatus: DataLoadingStatus;
  workflowLinkedTasks?: WorkflowWithTask[];
  loadingWorkflowTemplatesStatus: DataLoadingStatus;
  listWorkflowTemplates?: Workflow[];
  listWorkflowTemplateError?: Error;
  loadedListWorkflowTemplates: boolean;
  listWorkflowTemplateTotalSize?: number;
  workflowTemplate?: Workflow;
  workflowTemplateError?: Error;
}

const initialState: WorkflowTaskState = {
  workflowTaskData: {},
  workflowTaskError: null,
  loadingStatus: DataLoadingStatus.LOADING,
  workflowData: {},
  workflowError: null,
  workflowLinkedTasks: [],
  loadingWorkflowTemplatesStatus: DataLoadingStatus.INITIAL,
  listWorkflowTemplates: [],
  loadedListWorkflowTemplates: false,
};

export const workflowTaskReducer: Reducer<WorkflowTaskState> = (
  state: WorkflowTaskState = initialState,
  action: any,
) =>
  produce(state, (draft: WorkflowTaskState) => {
    switch (action.type) {
      case WorkflowStateActionType.GET_WORKFLOW_TASK_SUCCESS: {
        draft.workflowTaskData = action.payload;
        break;
      }
      case WorkflowStateActionType.GET_WORKFLOW_TASK_FAILURE: {
        draft.workflowTaskError = action.payload;
        draft.loadingStatus = DataLoadingStatus.ERROR;
        break;
      }
      case WorkflowStateActionType.GET_WORKFLOW_BY_ID: {
        draft.workflowData = action.payload;
        break;
      }
      case WorkflowStateActionType.UPDATE_WORKFLOW_LINKED_TASKS: {
        draft.workflowLinkedTasks = action.payload;
        draft.loadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case WorkflowStateActionType.CLEAR_WORKFLOW_WITH_TASK: {
        draft.workflowTaskData = {};
        draft.workflowData = {};
        draft.workflowTaskError = null;
        draft.loadingStatus = DataLoadingStatus.LOADING;
        draft.workflowError = null;
        draft.workflowLinkedTasks = [];

        break;
      }
      case WorkflowStateActionType.LIST_WORKFLOWS_TEMPLATE_ORBOT:
        draft.loadingWorkflowTemplatesStatus = DataLoadingStatus.LOADING;
        break;
      case WorkflowStateActionType.LIST_WORKFLOWS_TEMPLATE_COMPLETED_ORBOT: {
        draft.listWorkflowTemplates = action.payload;
        draft.listWorkflowTemplateTotalSize = action.totalSize;
        draft.loadingWorkflowTemplatesStatus = DataLoadingStatus.LOADED;
        draft.loadedListWorkflowTemplates = true;
        break;
      }
      case WorkflowStateActionType.LIST_WORKFLOWS_TEMPLATE_ERROR_ORBOT: {
        draft.loadingWorkflowTemplatesStatus = DataLoadingStatus.ERROR;
        draft.listWorkflowTemplateError = action.payload;
        break;
      }
      case WorkflowStateActionType.GET_WORKFLOW_TEMPLATE_COMPLETED_ORBOT: {
        draft.workflowTemplate = action.payload;
        break;
      }
      case WorkflowStateActionType.GET_WORKFLOW_TEMPLATE_ERROR_ORBOT: {
        draft.workflowTemplateError = action.payload;
        break;
      }
      default:
        break;
    }
  });
