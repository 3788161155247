import { all, call, put, takeLatest } from 'redux-saga/effects';
import { OrganizationActionType } from '../actions/actions.constants';
import {
  DeleteHolidayListRequest,
  GetHolidayListRequest,
  GetOrganizationRequest,
  HolidayList,
  ListHolidayListsRequest,
  ListHolidayListsResponse,
  UpdateOrganizationRequest,
  UpsertHolidayListRequest,
} from 'protos/pb/v1alpha1/organization_service';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { organizationsService } from '../../services/OrganizationsService';
import {
  deleteHolidayListCompletedAction,
  deleteHolidayListErrorAction,
  deleteHolidayListLoadingAction,
  getOrganizationCompletedAction,
  getOrganizationErrorAction,
  getSingleHolidayListCompletedAction,
  getSingleHolidayListErrorAction,
  getSingleHolidayListIsLoadingAction,
  listHolidayListsCompletedAction,
  listHolidayListsErrorAction,
  listHolidayListsIsLoadingAction,
  updateOrganizationCompletedAction,
  updateOrganizationErrorAction,
  upsertHolidayListCompletedAction,
  upsertHolidayListErrorAction,
  upsertHolidayListLoadingAction,
} from '../actions/organization.actions';

function* getOrganization(data: {
  type: OrganizationActionType.GET_ORGANIZATION;
  payload: GetOrganizationRequest;
}) {
  try {
    const { response, error }: { response: Organization; error: Error } =
      yield call(organizationsService.getOrganization, data.payload);
    if (response) {
      yield put(getOrganizationCompletedAction(response));
    } else {
      yield put(getOrganizationErrorAction(error));
    }
  } catch (error) {
    yield put(getOrganizationErrorAction(error as Error));
  }
}

function* updateOrganization(data: {
  type: OrganizationActionType.UPDATE_ORGANIZATION;
  payload: UpdateOrganizationRequest;
}): any {
  try {
    const { response, error }: { response: Organization; error: Error } =
      yield call(organizationsService.updateOrganization, data.payload);
    if (response) {
      yield put(updateOrganizationCompletedAction(response));
    } else {
      yield put(updateOrganizationErrorAction(error));
    }
  } catch (error) {
    yield put(updateOrganizationErrorAction(error as Error));
  }
}

function* listHolidayLists(data: {
  type: OrganizationActionType.LIST_HOLIDAY_LISTS;
  payload: ListHolidayListsRequest;
}) {
  try {
    yield put(listHolidayListsIsLoadingAction(true));
    const {
      response,
      error,
    }: { response: ListHolidayListsResponse; error: Error } = yield call(
      organizationsService.listHolidayLists,
      data.payload,
    );
    if (response) {
      yield put(listHolidayListsCompletedAction(response?.holidayLists || []));
    } else {
      yield put(listHolidayListsErrorAction(error));
    }
  } catch (error) {
    yield put(listHolidayListsErrorAction(error as Error));
  } finally {
    yield put(listHolidayListsIsLoadingAction(false));
  }
}

function* deleteHolidayList(data: {
  type: OrganizationActionType.DELETE_HOLIDAY_LIST;
  payload: DeleteHolidayListRequest;
}) {
  try {
    yield put(deleteHolidayListLoadingAction(true));
    const { response, error }: { response: boolean; error: Error } = yield call(
      organizationsService.deleteHolidayList,
      data.payload,
    );
    if (response) {
      yield put(deleteHolidayListCompletedAction(true));
    } else {
      yield put(deleteHolidayListErrorAction(error));
    }
  } catch (error) {
    yield put(deleteHolidayListErrorAction(error as Error));
  } finally {
    yield put(deleteHolidayListLoadingAction(false));
  }
}

function* upsertHolidayList(data: {
  type: OrganizationActionType.UPSERT_HOLIDAY_LIST;
  payload: UpsertHolidayListRequest;
}) {
  try {
    yield put(upsertHolidayListLoadingAction(true));
    const { response, error }: { response: boolean; error: Error } = yield call(
      organizationsService.upsertHolidayList,
      data.payload,
    );
    if (response) {
      yield put(upsertHolidayListCompletedAction(response));
    } else {
      yield put(upsertHolidayListErrorAction(error));
    }
  } catch (error) {
    yield put(upsertHolidayListErrorAction(error as Error));
  } finally {
    yield put(upsertHolidayListLoadingAction(false));
  }
}

function* getSingleHolidayList(data: {
  type: OrganizationActionType.GET_SINGLE_HOLIDAY;
  payload: GetHolidayListRequest;
}) {
  try {
    yield put(getSingleHolidayListIsLoadingAction(true));
    const { response, error }: { response: HolidayList; error: Error } =
      yield call(organizationsService.getHolidayList, data.payload);
    if (response) {
      yield put(getSingleHolidayListCompletedAction(response));
    } else {
      yield put(getSingleHolidayListErrorAction(error));
    }
  } catch (error) {
    yield put(getSingleHolidayListErrorAction(error as Error));
  } finally {
    yield put(getSingleHolidayListIsLoadingAction(false));
  }
}

function* organizationSaga() {
  yield all([
    takeLatest(OrganizationActionType.GET_ORGANIZATION, getOrganization),
    takeLatest(OrganizationActionType.UPDATE_ORGANIZATION, updateOrganization),
    takeLatest(OrganizationActionType.UPSERT_HOLIDAY_LIST, upsertHolidayList),
    takeLatest(OrganizationActionType.GET_SINGLE_HOLIDAY, getSingleHolidayList),
    takeLatest(OrganizationActionType.DELETE_HOLIDAY_LIST, deleteHolidayList),
    takeLatest(OrganizationActionType.LIST_HOLIDAY_LISTS, listHolidayLists),
  ]);
}

export default organizationSaga;
