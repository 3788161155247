import { createInstance, Identify } from '@amplitude/analytics-browser';
import { IS_PROD } from '../utils/constants';

const PAGE_VIEWED = 'Page Viewed';
const ELEMENT_CLICKED = 'Element Clicked';
const FORM_SUBMITTED = 'Form Submitted';
const MODAL_OPENED = 'Modal Opened';
const MODAL_CLOSED = 'Modal Closed';
const FILTER_APPLIED = 'Filter Applied';
const ELEMENT_HOVERED = 'Element Hovered';

type EventType =
  | typeof PAGE_VIEWED
  | typeof ELEMENT_CLICKED
  | typeof FORM_SUBMITTED
  | typeof MODAL_OPENED
  | typeof MODAL_CLOSED
  | typeof FILTER_APPLIED
  | typeof ELEMENT_HOVERED;

type PageViewEventProperties = Record<string, unknown> & {
  location: string;
  path: string;
  router_path: string;
};

type InteractionEventProperties = Record<string, unknown> & {
  element_name: string;
  element_type?: string;
  router_path?: string;
  location?: string;
  path?: string;
};

type FilterAppliedEventProperties = InteractionEventProperties & {
  filter_name: string;
  filter_value: string;
};

class AmplitudeService {
  private static _instance: AmplitudeService;
  private _amplitude: ReturnType<typeof createInstance> | undefined;
  private _isInitialized = false;

  static getInstance(): AmplitudeService {
    if (!this._instance) {
      this._instance = new AmplitudeService();
    }
    return this._instance;
  }

  init(): void {
    if (this._isInitialized) {
      return;
    }

    this._amplitude = createInstance();

    // Amplitude is only enabled in production
    if (IS_PROD) {
      this._amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || '', {
        autocapture: true,
      });
    }

    this._isInitialized = true;
  }

  setOrgResourceName(orgResourceName: string): void {
    // TODO: Change org name to use Amplitude groups instead
    const identify = new Identify();
    identify.set('org-resource-name', orgResourceName);
    this._amplitude?.identify(identify);
  }

  setUserId(userId: string): void {
    this._amplitude?.setUserId(userId);
  }

  private trackEvent(
    event: EventType,
    properties?: Record<string, unknown>,
  ): void {
    this._amplitude?.track(event, properties);
  }

  trackPageView(properties: PageViewEventProperties): void {
    this.trackEvent(PAGE_VIEWED, properties);
  }
  trackElementClick(properties: InteractionEventProperties): void {
    this.trackEvent(ELEMENT_CLICKED, properties);
  }
  trackFormSubmit(properties: InteractionEventProperties): void {
    this.trackEvent(FORM_SUBMITTED, properties);
  }
  trackModalOpen(properties: InteractionEventProperties): void {
    this.trackEvent(MODAL_OPENED, properties);
  }
  trackModalClose(properties: InteractionEventProperties): void {
    this.trackEvent(MODAL_CLOSED, properties);
  }
  trackElementHovered(properties: InteractionEventProperties): void {
    this.trackEvent(ELEMENT_HOVERED, properties);
  }
  trackFilterApplied(properties: FilterAppliedEventProperties): void {
    this.trackEvent(FILTER_APPLIED, properties);
  }
}

export const amplitudeService = AmplitudeService.getInstance();
