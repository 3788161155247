import produce from 'immer';
import { Connector } from 'protos/pb/v1alpha2/connector';
import { Reducer } from 'redux';
import { ConnectorActionType } from '../actions/actions.constants';
import { DataLoadingStatus } from '../../utils/constants';

export interface ConnectorState {
  connectors: Connector[];
  nextPageToken?: string;
  totalSize?: number;
  listConnectorsError?: Error;
  loadingConnectorsListStatus: DataLoadingStatus;

  createdConnector?: Connector;
  updatedConnector?: Connector;

  processingConnectorStatus: DataLoadingStatus;
  processConnectorError?: Error;

  selectedConnector?: Connector;
  selectedConnectorLoadingStatus: DataLoadingStatus;
  selectedConnectorError?: Error;
}

const initialState: ConnectorState = {
  connectors: [],
  loadingConnectorsListStatus: DataLoadingStatus.INITIAL,
  processingConnectorStatus: DataLoadingStatus.INITIAL,
  selectedConnectorLoadingStatus: DataLoadingStatus.INITIAL,
};

export const connectorReducer: Reducer<ConnectorState> = (
  state: ConnectorState = initialState,
  action: any,
) =>
  produce(state, (draft: ConnectorState) => {
    switch (action.type) {
      case ConnectorActionType.LIST_CONNECTORS:
        draft.loadingConnectorsListStatus = DataLoadingStatus.LOADING;
        break;
      case ConnectorActionType.LIST_CONNECTORS_COMPLETED: {
        if (action.refresh) {
          draft.connectors = action.payload;
        } else {
          draft.connectors.push(...action.payload);
        }
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.loadingConnectorsListStatus = DataLoadingStatus.LOADED;
        break;
      }
      case ConnectorActionType.LIST_CONNECTORS_ERROR: {
        draft.loadingConnectorsListStatus = DataLoadingStatus.ERROR;
        draft.listConnectorsError = action.payload;
        break;
      }
      case ConnectorActionType.CREATE_CONNECTOR:
        draft.processingConnectorStatus = DataLoadingStatus.LOADING;
        break;
      case ConnectorActionType.CREATE_CONNECTOR_COMPLETED: {
        draft.createdConnector = action.payload;
        draft.connectors.unshift(action.payload);
        draft.totalSize = (draft.totalSize as number) + 1;
        draft.processingConnectorStatus = DataLoadingStatus.LOADED;
        break;
      }
      case ConnectorActionType.CREATE_CONNECTOR_ERROR: {
        draft.processConnectorError = action.payload;
        draft.processingConnectorStatus = DataLoadingStatus.ERROR;
        break;
      }
      case ConnectorActionType.UPDATE_CONNECTOR:
        draft.processingConnectorStatus = DataLoadingStatus.LOADING;
        break;
      case ConnectorActionType.UPDATE_CONNECTOR_COMPLETED: {
        const connector: Connector = action.payload;
        const index: number = draft.connectors.findIndex(
          (c) => c.name === connector.name,
        );
        draft.updatedConnector = action.payload;
        draft.connectors.splice(index, 1, connector);
        draft.processingConnectorStatus = DataLoadingStatus.LOADED;
        break;
      }
      case ConnectorActionType.UPDATE_CONNECTOR_ERROR: {
        draft.processConnectorError = action.payload;
        draft.processingConnectorStatus = DataLoadingStatus.ERROR;
        break;
      }
      case ConnectorActionType.DELETE_CONNECTOR: {
        draft.processingConnectorStatus = DataLoadingStatus.LOADING;
        break;
      }
      case ConnectorActionType.DELETE_CONNECTOR_COMPLETED: {
        const updatedConnector = draft.connectors.find(
          (w) => w.name === action.payload,
        );
        if (updatedConnector) {
          draft.totalSize = (draft.totalSize as number) - 1;
        }
        draft.connectors = draft.connectors.filter(
          (w) => w.name !== action.payload,
        );
        draft.updatedConnector = updatedConnector;
        draft.processingConnectorStatus = DataLoadingStatus.LOADED;
        break;
      }
      case ConnectorActionType.DELETE_CONNECTOR_ERROR: {
        draft.processingConnectorStatus = DataLoadingStatus.ERROR;
        draft.processConnectorError = action.payload;
        break;
      }
      case ConnectorActionType.SET_SELECTED_CONNECTOR: {
        draft.selectedConnector = action.payload;
        break;
      }
      case ConnectorActionType.GET_CONNECTOR: {
        draft.selectedConnectorLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case ConnectorActionType.GET_CONNECTOR_COMPLETED: {
        draft.selectedConnectorLoadingStatus = DataLoadingStatus.LOADED;
        draft.selectedConnector = action.payload;
        break;
      }
      case ConnectorActionType.GET_CONNECTOR_ERROR: {
        draft.selectedConnectorLoadingStatus = DataLoadingStatus.ERROR;
        draft.selectedConnectorError = action.payload;
        break;
      }
      case ConnectorActionType.CLEAR: {
        draft.selectedConnector = undefined;
        draft.selectedConnectorError = undefined;
        break;
      }
      default:
        break;
    }
  });
