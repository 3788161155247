/**
 * This file consists of functions from the workflow-utils/src/action-classifier file
 * We have migrated the data structure - Action group and Prepared Action to the new Action
 */
import * as uuid from 'uuid';
import { Action } from 'protos/pb/v1alpha1/orbot_action';
import { ActionId } from './types';

export const containForeachAction = (action: Action | undefined) => {
  return !!action?.foreach;
};

export const containControlFlow = (action: Action): boolean => {
  return containForeachAction(action) || containConditionAction(action);
};

export const containConditionAction = (action: Action | undefined) => {
  return !!action?.condition;
};

export const hasFlagKeywords = (action: Action): boolean => {
  return !!action.flagKeywords;
};

export const hasDeteDuplicateLineItems = (action: Action): boolean => {
  if (!action) return false;
  return !!action.detectDuplicateLineItems;
};

export const containSmartAction = (action: Action): boolean => {
  if (
    action.flagKeywords ||
    action.detectDuplicateLineItems ||
    action.validate
  ) {
    return true;
  }
  return false;
};

export const isAnyChildActionInSet = (
  action: Action,
  set: Set<ActionId>,
): boolean => {
  if (!action) {
    return false;
  }
  const uuid = action.id;
  if (uuid && set.has(uuid)) {
    return true;
  }
  if (action.block) {
    return !!action.block.actions?.some((ag) => isAnyChildActionInSet(ag, set));
  }
  if (action.condition) {
    return !!action.condition.thenActions?.some((ag) =>
      isAnyChildActionInSet(ag, set),
    );
  }
  if (action.foreach) {
    return !!action.foreach.loopActions?.some((ag) =>
      isAnyChildActionInSet(ag, set),
    );
  }

  return false;
};

export const containFlagKeywordsAction = (action: Action) => {
  return !!action?.flagKeywords;
};

export const containGotoAction = (action: Action) => {
  return !!action?.goto;
};

export const containClickAction = (action: Action) => {
  return !!action?.click;
};

export const containSetValueAction = (action: Action) => {
  return !!action?.setValue;
};

export const containCreateTaskAction = (action: Action) => {
  return !!action?.createTask;
};

export const containExtractFieldsAction = (action: Action) => {
  return !!action?.extractFields;
};

export const isPlaceholderAction = (action: Action) => {
  const keys = Object.keys(action);
  return (
    keys.every((key) => key === 'id' || key === 'description') &&
    keys.length === 2
  );
};

export const createPlaceholderAction = (): Action => {
  return {
    id: uuid.v4(),
    description: '',
  };
};
