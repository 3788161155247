import {
  ArchivedResource,
  ListArchivedResourcesRequest,
  RestoreArchivedResourcesRequest,
  RestoreDeletedResourcesRequest,
} from 'protos/pb/v1alpha2/archived_resources_service';
import { ArchivedResourceActionType } from './actions.constants';

export const listArchivedResourcesAction = (
  req: ListArchivedResourcesRequest,
  refresh = false,
) => {
  return {
    type: ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES,
    payload: req,
    refresh,
  };
};

export const listArchivedResourcesCompletedAction = (
  list: ArchivedResource[],
  totalSize: number,
  refresh: boolean,
) => ({
  type: ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES_COMPLETED,
  payload: list,
  totalSize,
  refresh,
});

export const listArchivedResourcesErrorAction = (error?: Error) => ({
  type: ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES_ERROR,
  payload: error,
});

export const restoreArchivedResourcesAction = (
  req: RestoreArchivedResourcesRequest,
) => {
  return {
    type: ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES,
    payload: req,
  };
};

export const restoreArchivedResourcesCompletedAction = (
  restoredResources: string[], // name of all the restored resources
) => ({
  type: ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES_COMPLETED,
  payload: restoredResources,
});

export const restoreArchivedResourcesErrorAction = (error?: Error) => ({
  type: ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES_ERROR,
  payload: error,
});

export const restoreDeletedResourcesAction = (
  req: RestoreDeletedResourcesRequest,
) => ({
  type: ArchivedResourceActionType.RESTORE_DELETED_RESOURCES,
  payload: req,
});

export const restoreDeletedResourcesCompletedAction = (
  restored: boolean, // name of all the restored resources
) => ({
  type: ArchivedResourceActionType.RESTORE_DELETED_RESOURCES_COMPLETED,
  payload: restored,
});

export const restoreDeletedResourcesErrorAction = (error?: Error) => ({
  type: ArchivedResourceActionType.RESTORE_DELETED_RESOURCES_ERROR,
  payload: error,
});
