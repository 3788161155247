import { createSelector } from 'reselect';
import { DataLoadingStatus } from '../../utils/constants';
import { taskV2Selector } from './app.selectors';

export const tasksListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasks,
);

export const tasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksLoadingStatus === DataLoadingStatus.LOADING,
);

export const deletingTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.deletingTaskStatus === DataLoadingStatus.LOADING,
);

export const deletedTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.deletedTask,
);

export const tasksFailedToDeleteSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksFailedToDelete,
);

export const tasksRequestedForDeletionSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksRequestedForDeletion,
);

export const tasksDeletedSuccessfullySelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksDeletedSuccessfully,
);

export const tasksLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksLoadingStatus === DataLoadingStatus.LOADED,
);

export const tasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.nextPageToken,
);

export const tasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.totalSize,
);

export const pendingTasksListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasks,
);

export const pendingTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.pendingTasksLoadingStatus === DataLoadingStatus.LOADING,
);

export const pendingTasksLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.pendingTasksLoadingStatus === DataLoadingStatus.LOADED,
);

export const pendingTasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksNextPageToken,
);

export const pendingTasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksTotalSize,
);

export const pendingTasksAdditionalRoundListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRound,
);

export const pendingTasksAdditionalRoundLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.pendingTasksAdditionalRoundLoadingStatus ===
    DataLoadingStatus.LOADING,
);

export const pendingTasksAdditionalRoundLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.pendingTasksAdditionalRoundLoadingStatus ===
    DataLoadingStatus.LOADED,
);

export const pendingTasksAdditionalRoundNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRoundNextPageToken,
);

export const pendingTasksAdditionalRoundTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRoundTotalSize,
);

export const taskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.task,
);

export const taskLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.currentTaskLoadingStatus === DataLoadingStatus.LOADING,
);

export const taskLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.currentTaskLoadingStatus === DataLoadingStatus.LOADED,
);

export const taskEntityStatusesSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.entitiesInTask,
);

export const listPendingTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.pendingTasksLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.listPendingTasksError
      : undefined,
);

export const listTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.tasksLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.listTasksError
      : undefined,
);

export const listPendingTasksAdditionalRoundErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.pendingTasksAdditionalRoundLoadingStatus ===
    DataLoadingStatus.ERROR
      ? taskState.listPendingTasksAdditionalRoundError
      : undefined,
);

export const deleteTaskErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.deletingTaskStatus === DataLoadingStatus.ERROR
      ? taskState.deletingTaskError
      : undefined,
);

export const getTaskErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.currentTaskLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.getTaskError
      : undefined,
);

export const updateTaskErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.processingTaskStatus === DataLoadingStatus.ERROR
      ? taskState.updatingTaskError
      : undefined,
);

export const taskCreationErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.processingTaskStatus === DataLoadingStatus.ERROR
      ? taskState.taskCreationError
      : undefined,
);

export const processingTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.processingTaskStatus === DataLoadingStatus.LOADING,
);

export const addedTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.addedTask,
);

export const getConfidenceSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.confidence,
);
export const declinedTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.declinedTaskLoadingStatus === DataLoadingStatus.LOADING,
);
export const listDeclinedTasksSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTasks,
);
export const declinedTaskLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.declinedTaskLoadingStatus === DataLoadingStatus.LOADED,
);

export const declinedTasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedNextPageToken,
);

export const declinedTasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTotalSize,
);
export const listDeclinedTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTasksError,
);

export const systemDeclinedTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.systemDeclinedTaskLoadingStatus === DataLoadingStatus.LOADING,
);
export const listSystemDeclinedTasksSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTasks,
);
export const systemDeclinedTaskLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.systemDeclinedTaskLoadingStatus === DataLoadingStatus.LOADED,
);

export const systemDeclinedTasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedNextPageToken,
);

export const systemDeclinedTasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTotalSize,
);
export const listSystemDeclinedTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTasksError,
);

export const getUploadedDebugDocument = createSelector(
  [taskV2Selector],
  (taskState) => taskState.debugDocument,
);

export const taskDeclinedSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.taskDeclinedSuccess,
);

export const completedTaskSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.completedTaskSuccess,
);

export const taskFormatErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.taskFormatError,
);

export const updateBatchTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.updateBatchTasksLoadingStatus === DataLoadingStatus.LOADING,
);

export const updateBatchTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.updateBatchTasksLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.updateBatchTasksError
      : undefined,
);

export const updateBatchTasksSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.updateBatchTasksSuccess,
);

export const tasksFailedToReassignSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksFailedToReassign,
);

export const debugLayoutSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.enableDebugLayout,
);

export const showActivityMonitorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.showActivityMonitor,
);

export const apiExecutionHistoryListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.apiExecutionHistory,
);

export const apiExecutionHistoryLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.apiExecutionHistoryLoadingStatus === DataLoadingStatus.LOADING,
);

export const apiExecutionHistoryLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.apiExecutionHistoryLoadingStatus === DataLoadingStatus.LOADED,
);

export const apiExecutionHistoryTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.apiExecutionHistoryTotalSize,
);

export const listApiExecutionHistoryErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.apiExecutionHistoryLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.listApiExecutionHistoryError
      : undefined,
);

export const uiExecutionHistoryListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistory,
);

export const uiExecutionHistoryLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.uiExecutionHistoryLoadingStatus === DataLoadingStatus.LOADING,
);

export const uiExecutionHistoryNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistoryNextPageToken,
);

export const uiExecutionHistoryLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.uiExecutionHistoryLoadingStatus === DataLoadingStatus.LOADED,
);

export const uiExecutionHistoryTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistoryTotalSize,
);

export const listUiExecutionHistoryErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.uiExecutionHistoryLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.listUiExecutionHistoryError
      : undefined,
);

export const retryTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.retryTasksLoadingStatus === DataLoadingStatus.LOADING,
);

export const retryTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.retryTasksError,
);

export const retryTasksSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.retryTasksSuccess,
);

export const blockedWorkflowExecutionsCountSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.blockedWorkflowExecutionsCount,
);

export const blockedWorkflowResourceNamesSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.blockedWorkflowResourceNames,
);

export const deleteUiExecutionHistoryCompletedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.deleteUiExecutionHistoryCompleted,
);

export const deleteUiExecutionHistoryErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.deleteUiExecutionHistoryError,
);

export const exportTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.exportTasksLoadingStatus === DataLoadingStatus.LOADING,
);

export const exportTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.exportTasksLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.exportTasksError
      : undefined,
);

export const exportTasksResponseSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.exportTasksResponse,
);

export const exportTasksDownloadStatusSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.exportTasksDownloadLoadingStatus,
);

export const exportTasksDownloadErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) =>
    taskState.exportTasksDownloadLoadingStatus === DataLoadingStatus.ERROR
      ? taskState.exportTasksDownloadError
      : undefined,
);

export const downloadTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.downloadTasksLoadingStatus,
);

export const downloadTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.downloadTasksError,
);

export const downloadTasksDataSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.downloadTasksData,
);
