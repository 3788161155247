import _ from 'lodash';
import { Reducer } from 'react';
import { AnyAction } from 'redux';

// This is a custom higher order (reusable) reducer which takes care of
// undo/redo without actually changing the core functionality of passed reducer

interface UndoableState<T> {
  past: Array<T>;
  present: T;
  future: Array<T>;
  latestUnfiltered?: T;
}

export const undoable = <T>(
  reducer: Reducer<T, AnyAction>,
  options: {
    undoAction: string; // Undo Action Name
    redoAction: string; // Redo Action Name
    clearAction: string; // Clear Action Name
    forceSaveHistory?: (action: AnyAction) => boolean; // custom logic to save history even if states are equal
    filter?: (action: AnyAction) => boolean; // custom logic to prevent saving history
    preserveStateKeys?: (keyof T)[]; // preserve state across undo/redo
  },
) => {
  // Call the reducer with empty action to populate the initial state
  const initialState: UndoableState<T> = {
    past: [],
    present: reducer(undefined as any, {} as any),
    future: [],
    latestUnfiltered: reducer(undefined as any, {} as any),
  };

  // Return a reducer that handles undo and redo
  return function (state = initialState, action: AnyAction): UndoableState<T> {
    const { past, present, future } = state;
    switch (action.type) {
      case options.undoAction: {
        if (past.length === 0) {
          return state;
        }
        const previous = { ...past[past.length - 1] };
        const newPast = past.slice(0, past.length - 1);

        // Copy only the preserved keys using a for loop
        if (options.preserveStateKeys) {
          for (const key of options.preserveStateKeys) {
            previous[key] = present[key];
          }
        }

        return {
          past: newPast,
          present: previous,
          future: [present, ...future],
        };
      }

      case options.redoAction: {
        if (future.length === 0) {
          return state;
        }
        const next = { ...future[0] };
        const newFuture = future.slice(1);

        // Copy only the preserved keys using a for loop
        if (options.preserveStateKeys) {
          for (const key of options.preserveStateKeys) {
            next[key] = present[key];
          }
        }

        return {
          past: [...past, present],
          present: next,
          future: newFuture,
        };
      }

      case options.clearAction: {
        return {
          past: [],
          present,
          future: [],
        };
      }

      default: {
        // Delegate handling the action to the passed reducer
        const newPresent = reducer(present, action);
        // Only save history if the new state is different from the current state,
        // or if the forceSaveHistory function returns true. This ensures that history
        // is only updated when necessary, either due to changes in state or explicit
        // instruction to force save.
        if (
          !options.forceSaveHistory?.(action) &&
          _.isEqual(newPresent, present)
        ) {
          return { ...state, present: newPresent };
        }

        const isActionFiltered = options.filter?.(action) ?? false;

        const pastHistory = isActionFiltered
          ? past
          : [...past, state.latestUnfiltered || present];

        const latestUnfiltered = isActionFiltered
          ? state.latestUnfiltered || present
          : undefined;

        return {
          past: pastHistory,
          present: newPresent,
          future: isActionFiltered ? future : [],
          latestUnfiltered,
        };
      }
    }
  };
};
