import {
  CopyTasksRequest,
  CopyTasksResponse,
  CreateTaskRequest,
  DeleteBatchTasksRequest,
  DeleteBatchTasksResponse,
  DownloadTaskResultResponse,
  DownloadTaskResultRequest,
  ExportTasksDownloadFileRequest,
  ExportTasksDownloadFileResponse,
  ExportTasksRequest,
  ExportTasksResponse,
  GetTaskRequest,
  ListTasksRequest,
  ListTasksResponse,
  RetryTasksRequest,
  RetryTasksResponse,
  Task,
  TasksClientImpl,
  UpdateBatchTasksRequest,
  UpdateBatchTasksResponse,
  UpdateReviewTaskRequest,
  UpdateTaskRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { storageService } from './StorageService';
import { Observable } from 'rxjs';

export class TasksV2Service {
  private static instance: TasksV2Service;

  private static client = new TasksClientImpl(rpcWithErrorHandling);

  public static getInstance(): TasksV2Service {
    if (!this.instance) {
      this.instance = new TasksV2Service();
    }
    return this.instance;
  }

  async getTasksList(
    req: ListTasksRequest,
  ): Promise<{ response?: ListTasksResponse; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.ListTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createTask(
    req: CreateTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.CreateTask(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateTask(
    req: UpdateTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.UpdateTask(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateBatchTasks(
    req: UpdateBatchTasksRequest,
  ): Promise<{ response?: UpdateBatchTasksResponse; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.UpdateBatchTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getTask(
    req: GetTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.GetTask(
        req,
        getMetaData({ authorization }),
      );
      return { response: Task.fromJSON(response) };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteTask(
    req: DeleteBatchTasksRequest,
  ): Promise<{ response?: DeleteBatchTasksResponse; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.DeleteBatchTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async copyTask(
    req: CopyTasksRequest,
  ): Promise<{ response?: CopyTasksResponse; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.CopyTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async retryTask(
    req: RetryTasksRequest,
  ): Promise<{ response?: RetryTasksResponse; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.RetryTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getTaskSmartAction(
    req: GetTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.GetTask(
        req,
        getMetaData({ authorization }),
      );

      return { response: Task.fromJSON(response) };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateReviewTask(
    req: UpdateReviewTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.UpdateReviewTask(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async exportTasksCsv(
    req: ExportTasksRequest,
  ): Promise<{ response?: ExportTasksResponse; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.ExportTasks(
        req,
        getMetaData({ authorization }),
      );

      return { response: ExportTasksResponse.fromJSON(response) };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async exportTasksFileDownload(
    req: ExportTasksDownloadFileRequest,
  ): Promise<{ response?: ExportTasksDownloadFileResponse; error?: Error }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await TasksV2Service.client.ExportTasksDownloadFile(
        req,
        getMetaData({ authorization }),
      );

      return { response: ExportTasksDownloadFileResponse.fromJSON(response) };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async downloadTaskResult(req: DownloadTaskResultRequest): Promise<{
    response?: string;
    error?: Error;
  }> {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response: Observable<DownloadTaskResultResponse> =
        TasksV2Service.client.DownloadTaskResult(
          req,
          getMetaData({ authorization }),
        );
      /**
       * Downloads and assembles data chunk result data from a streaming response.
       * This handles the retrieval of potentially large data chunks by:
       * 1. Collecting all incoming binary chunks from the stream
       * 2. Efficiently consolidating them into a single contiguous Uint8Array
       * 3. Managing memory efficiently by pre-allocating the final buffer
       */

      // First pass: Collect all binary chunks and calculate total size
      // This prevents multiple buffer reallocations during concatenation
      const dataChunks: Uint8Array[] = [];
      let totalLength = 0;

      await response.forEach((resp) => {
        if (resp.dataChunk) {
          dataChunks.push(new Uint8Array(resp.dataChunk));
          totalLength += resp.dataChunk.length;
        }
      });

      // Second pass: Efficiently combine all chunks into a single buffer
      // Pre-allocate the exact size needed to avoid memory fragmentation
      const consolidatedDataChunks = new Uint8Array(totalLength);
      let offset = 0;

      dataChunks.forEach((chunk) => {
        consolidatedDataChunks.set(chunk, offset);
        offset += chunk.length;
      });
      // Decode the consolidated Uint8Array to a JSON string
      const decoder = new TextDecoder();
      const jsonString = decoder.decode(consolidatedDataChunks);
      return { response: jsonString };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const tasksV2Service = TasksV2Service.getInstance();
