import saveAs from 'file-saver';
import { Document } from 'protos/google/cloud/documentai/v1/document';
import { tasksV2Service } from '../../services/TasksV2Service';
import { TaskV2ActionType } from '../actions/actions.constants';
import {
  DeleteExecutionsRequest,
  ListWorkflowTasksRequest,
} from 'protos/pb/v1alpha1/orbot_service';
import {
  CreateTaskRequest,
  DeleteBatchTasksResponse,
  ExportTasksDownloadFileRequest,
  ExportTasksRequest,
  GetTaskRequest,
  ListTasksRequest,
  RetryTasksRequest,
  Task,
  UpdateBatchTasksRequest,
  UpdateTaskRequest,
  DownloadTaskResultRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  GetBlockedWorkflowExecutionStatisticsRequest,
  ListWorkflowExecutionsRequest,
} from 'protos/pb/v1alpha2/workflow_executions_service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { gcsService } from '../../services/GcsService';
import { orbotService } from '../../services/OrbotService';
import { workflowExecutionsService } from '../../services/WorkflowExecutionService';
import { DeleteTaskActionPayload } from '../../utils/constants';
import { getSelectedTaskDocument } from '../../utils/helpers';
import {
  createTaskCompletedAction,
  createTaskErrorAction,
  deleteTaskCompletedAction,
  deleteTaskErrorAction,
  deleteUiExecutionHistoryActionCompleted,
  deleteUiExecutionHistoryActionError,
  exportTasksCompletedAction,
  exportTasksErrorAction,
  exportTasksFileDownloadCompletedAction,
  exportTasksFileDownloadErrorAction,
  getBlockedWorkflowExecutionStatisticsCompletedAction,
  getBlockedWorkflowExecutionStatisticsErrorAction,
  getTaskCompletedAction,
  getTaskErrorAction,
  listApiExecutionHistoryCompletedAction,
  listApiExecutionHistoryErrorAction,
  listDeclinedTasksCompletedAction,
  listDeclinedTasksErrorAction,
  listPendingTasksAdditionalRoundCompletedAction,
  listPendingTasksAdditionalRoundErrorAction,
  listPendingTasksCompletedAction,
  listPendingTasksErrorAction,
  listSystemDeclinedTasksCompletedAction,
  listSystemDeclinedTasksErrorAction,
  listTasksCompletedAction,
  listTasksErrorAction,
  listUiExecutionHistoryCompletedAction,
  listUiExecutionHistoryErrorAction,
  retryTasksCompletedAction,
  retryTasksErrorAction,
  updateBatchTasksCompletedAction,
  updateBatchTasksErrorAction,
  updateTaskCompletedAction,
  updateTaskErrorAction,
  downloadTasksErrorAction,
  downloadTasksCompletedAction,
} from '../actions/taskV2.action';
import store from '../../redux/store';
import { taskErrorMsg } from '../../utils/errorMessages/task';

export function* listTasksSaga(data: {
  type: TaskV2ActionType;
  payload: ListTasksRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.getTasksList,
      data.payload,
    );
    if (response) {
      yield put(
        listTasksCompletedAction(
          response.tasks,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listTasksErrorAction(error));
    }
  } catch (error) {
    yield put(listTasksErrorAction(error as Error));
  }
}

export function* listDeclinedTasksSaga(data: {
  type: TaskV2ActionType;
  payload: ListTasksRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.getTasksList,
      data.payload,
    );
    if (response) {
      yield put(
        listDeclinedTasksCompletedAction(
          response.tasks,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listDeclinedTasksErrorAction(error));
    }
  } catch (error) {
    yield put(listDeclinedTasksErrorAction(error as Error));
  }
}

export function* listSystemDeclinedTasksSaga(data: {
  type: TaskV2ActionType;
  payload: ListTasksRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.getTasksList,
      data.payload,
    );
    if (response) {
      yield put(
        listSystemDeclinedTasksCompletedAction(
          response.tasks,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listSystemDeclinedTasksErrorAction(error));
    }
  } catch (error) {
    yield put(listSystemDeclinedTasksErrorAction(error as Error));
  }
}

export function* listPendingTasksSaga(data: {
  type: TaskV2ActionType;
  payload: ListTasksRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.getTasksList,
      data.payload,
    );
    if (response) {
      yield put(
        listPendingTasksCompletedAction(
          response.tasks,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listPendingTasksErrorAction(error));
    }
  } catch (error) {
    yield put(listPendingTasksErrorAction(error as Error));
  }
}

export function* listPendingTasksAdditionalRoundSaga(data: {
  type: TaskV2ActionType;
  payload: ListTasksRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.getTasksList,
      data.payload,
    );
    if (response) {
      yield put(
        listPendingTasksAdditionalRoundCompletedAction(
          response.tasks,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listPendingTasksAdditionalRoundErrorAction(error));
    }
  } catch (error) {
    yield put(listPendingTasksAdditionalRoundErrorAction(error as Error));
  }
}

export function* createTaskSaga(data: {
  type: TaskV2ActionType;
  payload: CreateTaskRequest;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.createTask,
      data.payload,
    );
    if (response) {
      yield put(createTaskCompletedAction(response));
    } else {
      yield put(createTaskErrorAction(error));
    }
  } catch (error) {
    yield put(createTaskErrorAction(error as Error));
  }
}

export function* updateTaskSaga(data: {
  type: TaskV2ActionType;
  payload: UpdateTaskRequest;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.updateTask,
      data.payload,
    );
    if (response) {
      yield put(updateTaskCompletedAction(response));
    } else {
      yield put(updateTaskErrorAction(error));
    }
  } catch (error) {
    yield put(updateTaskErrorAction(error as Error));
  }
}

export function* getTaskSaga(data: {
  type: TaskV2ActionType;
  payload: { req: GetTaskRequest; task: Task };
}): any {
  try {
    let responseTask, err;
    if (data.payload.task) {
      responseTask = data.payload.task;
    } else {
      const { response, error } = yield call(
        tasksV2Service.getTask,
        data.payload.req,
      );
      responseTask = response;
      err = error;
    }
    if (responseTask) {
      const task = responseTask;
      const documentStep = getSelectedTaskDocument(task);
      if (
        documentStep?.documents &&
        documentStep.documents.length > 0 &&
        documentStep.documents[0].uri
      ) {
        const { response, error } = yield call(
          gcsService.getUriContent,
          documentStep.documents[0].uri,
        );
        if (error) {
          yield put(
            getTaskErrorAction(
              new Error(`failed to fetch content for task: ${task.name}`),
            ),
          );
          return;
        } else {
          const textDoc = Document.decode(response);
          textDoc.uri = documentStep.documents[0].uri;
          documentStep.documents[0] = textDoc;
        }
      }
      yield put(getTaskCompletedAction(task));
    } else {
      yield put(getTaskErrorAction(err));
    }
  } catch (error) {
    yield put(getTaskErrorAction(error as Error));
  }
}

export function* deleteTaskSaga(data: {
  type: TaskV2ActionType;
  payload: DeleteTaskActionPayload;
}): any {
  try {
    const { req, deleteType } = data.payload;
    const {
      response,
      error,
    }: { response: DeleteBatchTasksResponse; error: Error } = yield call(
      tasksV2Service.deleteTask,
      req,
    );
    if (response) {
      yield put(deleteTaskCompletedAction({ res: response, deleteType }));
    } else {
      yield put(deleteTaskErrorAction(error));
    }
  } catch (error) {
    yield put(deleteTaskErrorAction(error as Error));
  }
}

export function* updateBatchTasksSaga(data: {
  type: TaskV2ActionType;
  payload: UpdateBatchTasksRequest;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.updateBatchTasks,
      data.payload,
    );
    if (response) {
      yield put(updateBatchTasksCompletedAction(response));
    } else {
      yield put(updateBatchTasksErrorAction(error));
    }
  } catch (error) {
    yield put(updateBatchTasksErrorAction(error as Error));
  }
}

export function* listUiExecutionHistorySaga(data: {
  type: TaskV2ActionType;
  payload: ListWorkflowTasksRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      orbotService.getUiExecutionHistory,
      data.payload,
    );

    if (response) {
      yield put(
        listUiExecutionHistoryCompletedAction(
          response.tasks,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listUiExecutionHistoryErrorAction(error));
    }
  } catch (error) {
    yield put(listUiExecutionHistoryErrorAction(error as Error));
  }
}

export function* listApiExecutionHistorySaga(data: {
  type: TaskV2ActionType;
  payload: ListWorkflowExecutionsRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      workflowExecutionsService.getApiExecutionHistory,
      data.payload,
    );
    if (response) {
      yield put(
        listApiExecutionHistoryCompletedAction(
          response.executions,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listApiExecutionHistoryErrorAction(error));
    }
  } catch (error) {
    yield put(listApiExecutionHistoryErrorAction(error as Error));
  }
}

export function* retryTasksSaga(data: {
  type: TaskV2ActionType;
  payload: RetryTasksRequest;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.retryTask,
      data.payload,
    );
    if (response) {
      yield put(retryTasksCompletedAction(response));
    } else {
      yield put(retryTasksErrorAction(error));
    }
  } catch (error) {
    yield put(retryTasksErrorAction(error as Error));
  }
}

export function* getBlockedWorkflowExecutionStatisticsSaga(data: {
  type: TaskV2ActionType;
  payload: GetBlockedWorkflowExecutionStatisticsRequest;
}): any {
  try {
    const { response, error } = yield call(
      workflowExecutionsService.getBlockedWorkflowExecutionStatistics,
      data.payload,
    );
    if (response) {
      yield put(getBlockedWorkflowExecutionStatisticsCompletedAction(response));
    } else {
      yield put(getBlockedWorkflowExecutionStatisticsErrorAction(error));
    }
  } catch (error) {
    yield put(getBlockedWorkflowExecutionStatisticsErrorAction(error as Error));
  }
}

export function* deleteUiExecutionHistorySaga(data: {
  type: TaskV2ActionType;
  payload: DeleteExecutionsRequest;
}): any {
  try {
    const { response, error } = yield call(
      orbotService.deleteUiExecutionHistory,
      data.payload,
    );
    if (response) {
      yield put(deleteUiExecutionHistoryActionCompleted(response));
    } else {
      yield put(
        deleteUiExecutionHistoryActionError(
          error?.message || 'An error occurred while deleting execution(s)',
        ),
      );
    }
  } catch (error) {
    yield put(
      deleteUiExecutionHistoryActionError(
        error?.message || 'An error occurred while deleting execution(s)',
      ),
    );
  }
}

export function* downloadTaskResultSaga(data: {
  type: TaskV2ActionType;
  payload: DownloadTaskResultRequest;
}): any {
  try {
    const { response, error }: { response: string; error: Error } = yield call(
      tasksV2Service.downloadTaskResult,
      data.payload,
    );

    if (response) {
      store.dispatch(downloadTasksCompletedAction(response));
    } else {
      yield put(
        downloadTasksErrorAction(error?.message || taskErrorMsg.downloadError),
      );
    }
  } catch (error) {
    yield put(
      downloadTasksErrorAction(error?.message || taskErrorMsg.downloadError),
    );
  }
}

export function* exportTasksSaga(data: {
  type: TaskV2ActionType;
  payload: ExportTasksRequest;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.exportTasksCsv,
      data.payload,
    );
    if (response) {
      yield put(exportTasksCompletedAction(response));
    } else {
      yield put(
        exportTasksErrorAction(
          error?.message || 'An error occurred while exporting tasks',
        ),
      );
    }
  } catch (error) {
    yield put(
      exportTasksErrorAction(
        error?.message || 'An error occurred while exporting tasks',
      ),
    );
  }
}

// The function will get the signed URL for the file and download the file
// using the signed URL and the file name provided in the response
export function* exportTasksDownloadSaga(data: {
  type: TaskV2ActionType;
  payload: ExportTasksDownloadFileRequest;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.exportTasksFileDownload,
      data.payload,
    );
    if (error) {
      yield put(
        exportTasksFileDownloadErrorAction(
          error?.message || 'An error occurred while exporting tasks',
        ),
      );
      return;
    }

    const fileName = response?.fileName || 'tasks.csv';

    const fileResp = yield fetch(response?.signedUrl as string);
    const fileData = yield fileResp.blob();
    saveAs(fileData, fileName); // Save the file using file-saver

    yield put(exportTasksFileDownloadCompletedAction());
  } catch (error) {
    yield put(
      exportTasksFileDownloadErrorAction(
        error?.message || 'An error occurred while exporting tasks',
      ),
    );
  }
}

function* taskV2Saga() {
  yield all([
    takeLatest(TaskV2ActionType.LIST_TASKS_V2, listTasksSaga),
    takeLatest(TaskV2ActionType.LIST_PENDING_TASKS, listPendingTasksSaga),
    takeLatest(
      TaskV2ActionType.LIST_PENDING_TASKS_ADDITIONAL_ROUND,
      listPendingTasksAdditionalRoundSaga,
    ),
    takeLatest(TaskV2ActionType.LIST_DECLINED_TASKS_V2, listDeclinedTasksSaga),
    takeLatest(
      TaskV2ActionType.LIST_SYSTEM_DECLINED_TASKS_V2,
      listSystemDeclinedTasksSaga,
    ),
    takeLatest(TaskV2ActionType.CREATE_TASK_V2, createTaskSaga),
    takeLatest(TaskV2ActionType.UPDATE_TASK_V2, updateTaskSaga),
    takeLatest(TaskV2ActionType.DELETE_TASK, deleteTaskSaga),
    takeLatest(TaskV2ActionType.GET_TASK, getTaskSaga),
    takeLatest(TaskV2ActionType.UPDATE_BATCH_TASKS, updateBatchTasksSaga),
    takeLatest(TaskV2ActionType.RETRY_TASKS, retryTasksSaga),
    takeLatest(
      TaskV2ActionType.LIST_API_EXECUTION_HISTORY,
      listApiExecutionHistorySaga,
    ),
    takeLatest(
      TaskV2ActionType.LIST_UI_EXECUTION_HISTORY,
      listUiExecutionHistorySaga,
    ),
    takeLatest(
      TaskV2ActionType.GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS,
      getBlockedWorkflowExecutionStatisticsSaga,
    ),
    takeLatest(
      TaskV2ActionType.DELETE_UI_EXECUTION_HISTORY,
      deleteUiExecutionHistorySaga,
    ),
    takeLatest(TaskV2ActionType.EXPORT_TASKS, exportTasksSaga),
    takeLatest(
      TaskV2ActionType.EXPORT_TASKS_DOWNLOAD_PENDING,
      exportTasksDownloadSaga,
    ),
    takeLatest(TaskV2ActionType.DOWNLOAD_TASKS, downloadTaskResultSaga),
  ]);
}

export default taskV2Saga;
