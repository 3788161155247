import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import { CSV_ENTITY_TYPES } from '../constants';

export const invalidCharactersClassificationMsg =
  'Entity name can only contain letters, numbers, spaces " ", underscores "_", or dashes "-"';
export const invalidNotesEntityNameMsg =
  'The "*" symbol should only be present at the beginning of the entity name.';
export const invalidCharactersInEntityNameMsg =
  'Entity name can only contain letters, numbers, spaces " ", notes "*", underscores "_", or dashes "-"';
export const invalidCharacterInOptionsMsg =
  'Option can only contain letters, numbers, spaces " ", underscores "_", or dashes "-"';

export const invalidFileNameMsg = `Validation error: Entity "file name" should be present and its normalizationType should be ${EntityDataType[EntityDataType.ENTITY_TYPE_UNSPECIFIED]}`;

export const incorrectTypeForOptionsMsg = (args: {
  name: string;
  type: string;
  isYAML?: boolean;
}) =>
  `Entity "${args.name}" has options specified but has incorrect entity_type "${args.type}", expected entity_type "${CSV_ENTITY_TYPES[EntityDataType.ENTITY_TYPE_CHOICE]}".`;

export const missingEntityTypeMsg = (args: { name: string }) =>
  `Entity "${args.name}" is missing the required "entity_type" field.`;

export const invalidEntityTypeMsg = (args: {
  name: string;
  type: string;
  supportedTypes: string[];
}) =>
  `Entity "${args.name}" has an invalid entity_type "${args.type}". Supported types: ${args.supportedTypes.join(', ')}.`;

export const incorrectTypeForNotesMsg = (args: {
  name: string;
  type: string;
  isYAML?: boolean;
}) =>
  `Entity "${args.name}" starts with * but has incorrect ${args.isYAML ? 'normalization type' : 'entity_type'} "${args.type}", expected ${args.isYAML ? 'normalization type' : 'entity_type'} "${CSV_ENTITY_TYPES[EntityDataType.ENTITY_TYPE_ANNOTATION]}".`;

export const duplicateOptionsMsg = (args: { name: string }) =>
  `Entity "${args.name}" has duplicate options specified`;

export const invalidCharactersMsg = (args: { name: string }) =>
  `Entity "${args.name}": ${invalidCharactersInEntityNameMsg}`;

export const invalidCharactersOptionMsg = (args: { name: string }) =>
  `Entity "${args.name}": ${invalidCharacterInOptionsMsg} `;

export const invalidNotesMsg = (args: { name: string }) =>
  `Entity "${args.name}": ${invalidNotesEntityNameMsg}`;

export const entityAlreadyExistsMsg = (args: { name: string }) =>
  `Entity "${args.name}" already exists.`;

export const childEntityAlreadyExistMsg = (args: {
  parent: string;
  child: string;
}) => `Entity "${args.child}" for parent "${args.parent}" already exists.`;
