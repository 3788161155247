import produce from 'immer';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { Reducer } from 'redux';
import { WorkflowDetailsType } from '../actions/actions.constants';
import { ActionableElementObject } from 'workflow-utils/src/types';
import {
  addActionAfter,
  getActionById,
  getActions,
  removeAction,
} from 'workflow-utils/src/workflow';
import { updateSecretIDsInWorkflow } from '../../utils/OrbotWorkflowUtils';
import { ListSchedulesResponse } from 'protos/pb/v1alpha1/schedule_service';
import { Schedule } from 'protos/pb/v1alpha1/schedule';
import { DataLoadingStatus } from '../../utils/constants';
import { Action } from 'protos/pb/v1alpha1/orbot_action';
import { createPlaceholderAction } from 'workflow-utils/src/action-classifier';

export interface WorkflowDetailsState {
  workflow: Workflow | null;
  actionableElementsDict: Record<string, ActionableElementObject[]>;
  hasUnsavedChanges: boolean;
  invalidateChanges: Set<string>;
  loadingStatus: DataLoadingStatus;
  schedules: ListSchedulesResponse;
  schedulesLoadingStatus: DataLoadingStatus;
  createWorkflowScheduleLoadingStatus: DataLoadingStatus;
  createWorkflowScheduleError: Error | null;
  deleteWorkflowScheduleError: Error | null;
  schedule: Schedule | null;
  schedulesError: Error | null;
}

export const initialState: WorkflowDetailsState = {
  workflow: null,
  actionableElementsDict: {},
  hasUnsavedChanges: false,
  invalidateChanges: new Set<string>(),
  loadingStatus: DataLoadingStatus.INITIAL,
  schedules: {},
  schedulesLoadingStatus: DataLoadingStatus.INITIAL,
  createWorkflowScheduleLoadingStatus: DataLoadingStatus.INITIAL,
  createWorkflowScheduleError: null,
  deleteWorkflowScheduleError: null,
  schedule: null,
  schedulesError: null,
};

export const workflowDetailsReducer: Reducer<WorkflowDetailsState> = (
  state: WorkflowDetailsState = initialState,
  action: any,
) =>
  produce(state, (draft: WorkflowDetailsState) => {
    switch (action.type) {
      case WorkflowDetailsType.SET_WORKFLOW_SUCCESS: {
        draft.workflow = action.payload;
        break;
      }
      case WorkflowDetailsType.CHANGE_LOADING_STATUS: {
        draft.loadingStatus = action.payload
          ? DataLoadingStatus.LOADING
          : DataLoadingStatus.LOADED;
        break;
      }
      case WorkflowDetailsType.GET_WORKFLOW_FAILURE: {
        draft.workflow = action.payload;
        draft.loadingStatus = DataLoadingStatus.ERROR;
        break;
      }

      case WorkflowDetailsType.UPDATE_WORKFLOW_TITLE: {
        const title = action.payload.trim();
        draft.workflow = {
          ...draft.workflow,
          displayName: title,
        };
        draft.hasUnsavedChanges = true;
        draft.invalidateChanges = title
          ? new Set(
              [...state.invalidateChanges].filter(
                (field) => field !== 'displayName',
              ),
            )
          : new Set([...state.invalidateChanges, 'displayName']);
        break;
      }
      case WorkflowDetailsType.SET_HAS_UNSAVED_CHANGES: {
        draft.hasUnsavedChanges = action.payload;
        break;
      }
      case WorkflowDetailsType.ADD_INVALID_CHANGE: {
        draft.invalidateChanges = new Set([
          ...state.invalidateChanges,
          action.payload,
        ]);
        break;
      }
      case WorkflowDetailsType.DELETE_INVALID_CHANGE: {
        draft.invalidateChanges = new Set(
          [...state.invalidateChanges].filter(
            (field) => field !== action.payload,
          ),
        );
        break;
      }
      case WorkflowDetailsType.SET_ACTIONS_FOR_REVIEW: {
        if (draft.workflow) {
          draft.workflow.actionsForReview = action.payload;
          draft.hasUnsavedChanges = true;
        }
        break;
      }
      case WorkflowDetailsType.SET_LOW_CONFIDENCE_THRESHOLD: {
        if (draft.workflow) {
          draft.workflow.lowConfidenceThreshold = action.payload;
          draft.hasUnsavedChanges = true;
        }
        break;
      }
      case WorkflowDetailsType.ADD_REVIEWER: {
        if (draft.workflow) {
          if (draft.workflow.reviewerIds?.includes(action.payload)) {
            break;
          }

          draft.workflow.reviewerIds = [
            ...(draft.workflow.reviewerIds ?? []),
            action.payload,
          ];
          draft.hasUnsavedChanges = true;
        }

        break;
      }

      case WorkflowDetailsType.REMOVE_REVIEWER: {
        if (draft.workflow) {
          if (!draft.workflow.reviewerIds?.includes(action.payload)) {
            break;
          }

          draft.workflow.reviewerIds = (
            draft.workflow.reviewerIds ?? []
          ).filter((reviewerId) => reviewerId !== action.payload);

          draft.hasUnsavedChanges = true;
        }
        break;
      }

      case WorkflowDetailsType.ADD_ADMIN: {
        if (draft.workflow) {
          // As any has been added as a quick fix for adminIds
          // When we would have them inside the workflow type, we can remove the any type
          if (draft.workflow.adminIds?.includes(action.payload)) {
            break;
          }

          draft.workflow.adminIds = [
            ...(draft.workflow.adminIds ?? []),
            action.payload,
          ];
          draft.hasUnsavedChanges = true;
        }

        break;
      }

      case WorkflowDetailsType.REMOVE_ADMIN: {
        if (draft.workflow) {
          // As any has been added as a quick fix for adminIds
          // When we would have them inside the workflow type, we can remove the any type
          if (!draft.workflow.adminIds?.includes(action.payload)) {
            break;
          }

          draft.workflow.adminIds = (draft.workflow.adminIds ?? []).filter(
            (adminId: string) => adminId !== action.payload,
          );

          draft.hasUnsavedChanges = true;
        }
        break;
      }

      case WorkflowDetailsType.ADD_ACTION_AFTER: {
        const { actionId, processId } = action.payload;

        const actions = getActions(draft.workflow!, processId);
        addActionAfter(createPlaceholderAction(), actionId, actions);

        // Mark the draft state as having unsaved changes.
        draft.hasUnsavedChanges = true;

        break;
      }

      case WorkflowDetailsType.REMOVE_ACTION: {
        const { actionId, processId } = action.payload;
        const actions = getActions(draft?.workflow, processId);
        const actionById = getActionById(actionId, actions);

        let referencedId: string | undefined = undefined;
        if (actionById?.condition?.thenActions) {
          referencedId = actionById.condition!.condition!.referenceValue;
        } else if (actionById?.setValue) {
          referencedId = actionById.setValue!.fieldValue?.referenceValue;
        }

        if (referencedId) {
          removeAction(referencedId, actions ?? []);
        }

        removeAction(actionId, actions ?? []);

        // Mark the draft state as having unsaved changes.
        draft.hasUnsavedChanges = true;

        break;
      }

      case WorkflowDetailsType.ADD_FALSE_CONDITION_ACTION: {
        const { actionId, processId } = action.payload;

        const actions = getActions(draft.workflow, processId);
        const actionById = getActionById(actionId, actions);
        if (
          actionById?.condition &&
          (actionById.condition.thenActions ?? []).length > 0 &&
          (actionById.condition.elseActions ?? []).length === 0
        ) {
          actionById.condition.elseActions = [createPlaceholderAction()];
        }

        // Mark the draft state as having unsaved changes.
        draft.hasUnsavedChanges = true;

        break;
      }

      case WorkflowDetailsType.UPDATE_ACTION: {
        const {
          action: actionItem,
          updated,
          processId,
        }: {
          action: Action;
          updated: Action;
          processId?: string;
        } = action.payload;

        if (!updated) {
          break;
        }

        const id = actionItem.id;
        const actions = getActions(draft.workflow, processId);
        const selectedElementToUpdate = getActionById(id!, actions!)!;

        Object.assign(selectedElementToUpdate, updated);

        draft.hasUnsavedChanges = true;

        break;
      }

      case WorkflowDetailsType.SET_SCHEDULES: {
        const payloadSchedules = action.payload.schedules.schedules || [];
        if (action.payload.reset) {
          draft.schedules.schedules = payloadSchedules;
        } else {
          draft.schedules.schedules = [
            ...(draft.schedules.schedules ?? []),
            ...payloadSchedules,
          ];
        }

        draft.schedules.totalSize = action.payload.schedules.totalSize;
        draft.schedulesLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }

      case WorkflowDetailsType.CHANGE_SCHEDULES_LOADING_STATUS: {
        draft.schedulesLoadingStatus = action.payload
          ? DataLoadingStatus.LOADING
          : DataLoadingStatus.LOADED;
        break;
      }

      case WorkflowDetailsType.CREATE_UPDATE_SCHEDULE_LOADING: {
        draft.createWorkflowScheduleLoadingStatus = action.payload
          ? DataLoadingStatus.LOADING
          : DataLoadingStatus.LOADED;
        break;
      }

      case WorkflowDetailsType.CREATE_UPDATE_SCHEDULE_ERROR: {
        draft.createWorkflowScheduleError = action.payload;
        break;
      }

      case WorkflowDetailsType.UPDATE_SECRET_IDS_IN_WORKFLOW: {
        const { wfSecretStoreItem } = action.payload;
        if (draft.workflow) {
          if (
            updateSecretIDsInWorkflow(
              wfSecretStoreItem.secretBlock,
              wfSecretStoreItem.actionIDs,
              draft.workflow,
            )
          ) {
            draft.hasUnsavedChanges = true;
          }
        }
        break;
      }

      case WorkflowDetailsType.DELETE_SCHEDULE_ERROR: {
        draft.deleteWorkflowScheduleError = action.payload;
        break;
      }

      case WorkflowDetailsType.SET_SCHEDULE: {
        draft.schedule = action.payload;
        break;
      }

      case WorkflowDetailsType.FETCH_SCHEDULES_ERROR: {
        draft.schedulesError = action.payload;
        break;
      }

      default:
        break;
    }
  });
