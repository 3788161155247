import driveSvg from '../../static/icons/drive-logo.svg';
import gmailSvg from '../../static/icons/gmail-logo.svg';
import sheetsSvg from '../../static/icons/sheets-logo.svg';
import salesForceSvg from '../../static/icons/sales-force-logo.svg';
import sapSvg from '../../static/icons/sap-logo.svg';
import sftpSvg from '../../static/icons/sftp-logo.svg';
import outlookSvg from '../../static/icons/outlook.svg';
import excelSvg from '../../static/icons/spreadsheet.svg';
import jsonSvg from '../../static/icons/json.svg';
import uiAutomationSvg from '../../static/icons/ui-automation.svg';
import extractionSvg from '../../static/icons/extraction.svg';
import classificationSvg from '../../static/icons/classification.svg';
import { ApplicationName } from '../../utils/protos/enums';

export const getLogoByName = (name: string) => {
  switch (name) {
    case ApplicationName.GoogleDrive:
      return driveSvg;
    case ApplicationName.Outlook:
      return outlookSvg;
    case ApplicationName.Gmail:
      return gmailSvg;
    case ApplicationName.MSExcel:
      return excelSvg;
    case ApplicationName.GoogleSheets:
      return sheetsSvg;
    case ApplicationName.SalesForce:
      return salesForceSvg;
    case ApplicationName.Sap:
      return sapSvg;
    case ApplicationName.SftpServer:
      return sftpSvg;
    case ApplicationName.GenerateOutput:
      return jsonSvg;
    case ApplicationName.UiAutomation:
      return uiAutomationSvg;
    case ApplicationName.DocumentClassification:
      return classificationSvg;
    case ApplicationName.EntityExtraction:
      return extractionSvg;
  }
};
