import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface RouterState {
  routerPath: string;
}

const initialState: RouterState = {
  routerPath: '',
};

export const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setRouterPath: (state, action: PayloadAction<string>) => {
      state.routerPath = action.payload;
    },
  },
  selectors: {
    routerPathSelector: (state) => state.routerPath,
  },
});

export const { setRouterPath } = routerSlice.actions;

export const { routerPathSelector } = routerSlice.selectors;

export default routerSlice.reducer;
