import produce from 'immer';
import { Reducer } from 'redux';
import { ArchivedResource } from 'protos/pb/v1alpha2/archived_resources_service';
import { ArchivedResourceActionType } from '../actions/actions.constants';
import { DataLoadingStatus } from '../../utils/constants';

export interface ArchivedResourceState {
  list: ArchivedResource[];
  listLoadingStatus: DataLoadingStatus;
  listingError?: Error;
  restoringStatus: DataLoadingStatus;
  restoringError?: Error;
  restoredDeleted: boolean;
  restoredDeletedError?: Error;
  totalSize?: number;
}

const initialState: ArchivedResourceState = {
  list: [],
  listLoadingStatus: DataLoadingStatus.INITIAL,
  restoringStatus: DataLoadingStatus.INITIAL,
  restoredDeleted: false,
};

export const archivedResourcesReducer: Reducer<ArchivedResourceState> = (
  state: ArchivedResourceState = initialState,
  action: any,
) =>
  produce(state, (draft: ArchivedResourceState) => {
    switch (action.type) {
      case ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES:
        draft.listLoadingStatus = DataLoadingStatus.LOADING;
        break;
      case ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES_COMPLETED: {
        if (action.refresh) {
          draft.list = action.payload;
        } else {
          draft.list.push(...action.payload);
        }
        draft.totalSize = action.totalSize;
        draft.listLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case ArchivedResourceActionType.LIST_ARCHIVED_RESOURCES_ERROR: {
        draft.listLoadingStatus = DataLoadingStatus.ERROR;
        draft.listingError = action.payload;
        break;
      }
      case ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES:
        draft.restoringStatus = DataLoadingStatus.LOADING;
        break;
      case ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES_COMPLETED: {
        draft.list = draft.list.filter((t) => {
          if (t.task) {
            return !action.payload.includes(t.task.name);
          }
          if (t.workflow) {
            return !action.payload.includes(t.workflow.name);
          }
          if (t.connector) {
            return !action.payload.includes(t.connector.name);
          }
          if (t.execution) {
            return !action.payload.includes(t.execution.id);
          }
          return true;
        });
        draft.totalSize = (draft.totalSize as number) - action.payload.length;
        draft.restoringStatus = DataLoadingStatus.LOADED;
        break;
      }
      case ArchivedResourceActionType.RESTORE_ARCHIVED_RESOURCES_ERROR: {
        draft.restoringStatus = DataLoadingStatus.ERROR;
        draft.restoringError = action.payload;
        break;
      }
      case ArchivedResourceActionType.RESTORE_DELETED_RESOURCES_COMPLETED: {
        draft.restoredDeleted = action.payload;
        break;
      }
      case ArchivedResourceActionType.RESTORE_DELETED_RESOURCES_ERROR: {
        draft.restoredDeleted = false;
        draft.restoredDeletedError = action.payload;
        break;
      }
      default:
        break;
    }
  });
