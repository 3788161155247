import { WorkflowUser } from 'protos/common/review';
import SearchField from './SearchField';
import { FC, memo } from 'react';

interface AddUserFieldProps {
  width?: string;
  hasError?: boolean;
  searchList: string[];
  searchValues: WorkflowUser[];
  onChange: (x: any) => void;
  disabled?: boolean;
}

const AddUserField: FC<AddUserFieldProps> = ({
  width = '100%',
  hasError,
  searchList,
  searchValues,
  onChange,
  disabled,
}) => {
  return (
    <SearchField
      height='41px'
      searchStyle={{
        marginTop: '5px',
        border: hasError && '0.75px solid #EB0000',
        width: width,
      }}
      dropdownWidth={width}
      className={'add-user-input'}
      searchList={searchList}
      selectedValues={searchValues.map((user) => user.user)}
      handleAdd={onChange}
      placeholder='Start typing email'
      ariaLabel='Start typing email'
      ariaDescribeBy='invite_users'
      disabled={disabled}
    />
  );
};

export default memo(AddUserField);
