import { SecretBlock } from 'protos/pb/v1alpha1/secret_manager';
import { createReducer } from '@reduxjs/toolkit';
import {
  setSecretsInfoFromWorkflowAction,
  createSecretBlockCompletedAction,
  createSecretBlockErrorAction,
  updateSecretBlockCompletedAction,
  updateSecretBlockErrorAction,
} from '../actions/workflow_secrets.actions';
import { DataLoadingStatus } from '../../utils/constants';
export interface WorkflowSecretStoreItem {
  // the root actions that are using this secret block. This is probably actions
  // with the description "Login" or similar.
  actionIDs: Set<string>;

  // The secrets in the secret block either have their ID field set (with the real ID),
  // or displayName. Prefer the ID field when possible, but if not (for example,
  // workflow secretValue is in template form), then use displayName.
  secretBlock: SecretBlock;
  isCreated: boolean;
}

export interface WorkflowSecretStore {
  [key: string]: WorkflowSecretStoreItem;
}

export interface WorkflowSecretState {
  workflowSecretStore: WorkflowSecretStore;
  loadingStatus: DataLoadingStatus;
}

const initialState: WorkflowSecretState = {
  workflowSecretStore: {},
  loadingStatus: DataLoadingStatus.LOADING,
};

export const workflowSecretReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSecretsInfoFromWorkflowAction, (state, action) => {
      state.workflowSecretStore = action.payload.workflowSecretStore;
      state.loadingStatus = DataLoadingStatus.LOADED;
    })
    .addCase(createSecretBlockCompletedAction, (state, action) => {
      const secretBlock = action.payload.secretBlock;
      if (!state.workflowSecretStore[secretBlock.blockName || '']) {
        throw new Error(
          `Secret block ${secretBlock.blockName} not found in workflow secret store`,
        );
      }

      // For now, manually set the hasOverride fields becasue we don't
      // have them in the API response yet.
      // Todo: have to handle the case where we create a public secret
      secretBlock.secretInfos?.forEach((secretInfo) => {
        secretInfo.hasOverride = true;
      });

      state.workflowSecretStore[secretBlock.blockName || ''].secretBlock =
        secretBlock;

      state.workflowSecretStore[secretBlock.blockName || ''].isCreated = true;
    })
    .addCase(createSecretBlockErrorAction, (state, action) => {
      console.error(
        'Error happened when creating secret block',
        action.payload.error,
      );
    })
    .addCase(updateSecretBlockCompletedAction, (state, action) => {
      const incomingBlock = action.payload.secretBlock;
      const secretStoreItem =
        state.workflowSecretStore[incomingBlock.blockName || ''];
      if (!secretStoreItem) {
        throw new Error(
          `Secret block ${incomingBlock.blockName} not found in workflow secret store`,
        );
      }

      // Update the secret IDs in the store with the incoming block's IDs
      secretStoreItem.secretBlock.secretInfos = (
        secretStoreItem.secretBlock.secretInfos || []
      ).map((secretInfo) => {
        const matchingIncomingInfo = (incomingBlock.secretInfos || []).find(
          (incomingInfo) =>
            incomingInfo.secret?.displayName === secretInfo.secret?.displayName,
        );

        if (matchingIncomingInfo?.secret?.id) {
          return {
            ...secretInfo,
            secret: {
              ...secretInfo.secret,
              id: matchingIncomingInfo.secret.id,
            },
          };
        }

        return secretInfo;
      });

      // For now, manually set the hasOverride fields becasue we don't
      // have them in the API response yet.
      // Todo: have to handle the case where we create a public secret
      secretStoreItem.secretBlock.secretInfos?.forEach((secretInfo) => {
        secretInfo.hasOverride = true;
      });

      secretStoreItem.isCreated = true;
    })
    .addCase(updateSecretBlockErrorAction, (state, action) => {
      console.error(
        'Error happened when updating secret block',
        action.payload.error,
      );
    });
});
