import { toast, ToastOptions, ToastContent, Id } from 'react-toastify';
import OrbyColorPalette from '../colors/ColorPalette';
import React from 'react';

import ToastSuccessIcon from './icons/toast-success.svg';
import ToastWarningIcon from './icons/toast-warning.svg';
import ToastErrorIcon from './icons/toast-error.svg';
import ToastInfoIcon from './icons/toast-info-white.svg';

export type OrbyToastOptions = ToastOptions;
export type OrbyToastContent = ToastContent;
export type OrbyToastId = Id;

export function showSuccessToast(
  content: OrbyToastContent,
  config?: OrbyToastOptions,
  dismissExisting: boolean = true,
): OrbyToastId {
  if (dismissExisting) {
    toast.dismiss();
  }
  return toast(content, {
    type: 'success',
    position: 'top-right',
    autoClose: 2000,
    closeOnClick: false,
    draggable: false,
    style: { backgroundColor: OrbyColorPalette['green-50'] },
    icon: getIcon(ToastSuccessIcon),
    bodyStyle: { alignItems: 'flex-start' },
    ...config,
  });
}

export function showWarningToast(
  content: OrbyToastContent,
  config?: OrbyToastOptions,
  dismissExisting: boolean = true,
): OrbyToastId {
  if (dismissExisting) {
    toast.dismiss();
  }
  return toast(content, {
    type: 'warning',
    position: 'top-right',
    autoClose: false, // By default warning toast messages wont auto close
    closeOnClick: false,
    draggable: false,
    style: { backgroundColor: OrbyColorPalette['warning-100'] },
    icon: getIcon(ToastWarningIcon),
    bodyStyle: { alignItems: 'flex-start' },
    ...config,
  });
}

export function showErrorToast(
  content: OrbyToastContent,
  config?: OrbyToastOptions,
  dismissExisting: boolean = true,
): OrbyToastId {
  if (dismissExisting) {
    toast.dismiss();
  }
  return toast(content, {
    type: 'error',
    position: 'top-right',
    autoClose: false, // By default error toast messages wont auto close
    closeOnClick: false,
    draggable: false,
    style: { backgroundColor: OrbyColorPalette['error-100'] },
    icon: getIcon(ToastErrorIcon),
    bodyStyle: { alignItems: 'flex-start' },
    ...config,
  });
}

export function showInfoToast(
  content: OrbyToastContent,
  config?: OrbyToastOptions,
  dismissExisting: boolean = true,
): OrbyToastId {
  if (dismissExisting) {
    toast.dismiss();
  }
  return toast(content, {
    type: 'info',
    position: 'top-right',
    autoClose: 1000,
    closeOnClick: false,
    draggable: false,
    style: { backgroundColor: OrbyColorPalette['blue-50'] },
    icon: getIcon(ToastInfoIcon),
    bodyStyle: { alignItems: 'flex-start' },
    ...config,
  });
}

export function dismissToast(toastRef?: OrbyToastId) {
  toast.dismiss(toastRef);
}

function getIcon(src: string) {
  return <img src={src} alt='Toast Icon' width={20} height={20} />;
}
