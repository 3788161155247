import produce from 'immer';
import { Reducer } from 'redux';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { OrganizationActionType } from '../actions/actions.constants';
import { HolidayList } from 'protos/pb/v1alpha1/organization_service';
import { DataLoadingStatus } from '../../utils/constants';

export interface OrganizationState {
  organization?: Organization;
  organizationUpdating: boolean;
  organizationUpdated: boolean;
  organizationLoading: boolean;
  organizationLoadingError?: Error;

  upsertHolidayListSuccess: boolean;
  upsertHolidayListError?: Error;
  upsertHolidayListLoading: boolean;

  holidayLists: HolidayList[];
  isLoadingHolidayLists: boolean;
  listHolidayListsError?: Error;

  singleHolidayList?: HolidayList;
  singleHolidayListLoading: boolean;
  getSingleHolidayListError?: Error;

  deleteHolidayListSuccess: boolean;
  deleteHolidayListError?: Error;
  deleteHolidayListLoading: boolean;

  organizationStatus: DataLoadingStatus;
  organizationError?: Error;

  updateOrganizationStatus: DataLoadingStatus;
  updateOrganizationError?: Error;
}

const initialState: OrganizationState = {
  organizationStatus: DataLoadingStatus.INITIAL,
  updateOrganizationStatus: DataLoadingStatus.INITIAL,

  organizationUpdating: false,
  organizationUpdated: false,
  organizationLoading: false,

  upsertHolidayListSuccess: false,
  upsertHolidayListError: undefined,

  holidayLists: [],
  isLoadingHolidayLists: false,
  listHolidayListsError: undefined,

  singleHolidayList: undefined,
  getSingleHolidayListError: undefined,
  singleHolidayListLoading: false,

  deleteHolidayListSuccess: false,
  deleteHolidayListError: undefined,
  upsertHolidayListLoading: false,

  deleteHolidayListLoading: false,
};

export const organizationReducer: Reducer<OrganizationState> = (
  state: OrganizationState = initialState,
  action: any,
) =>
  produce(state, (draft: OrganizationState) => {
    switch (action.type) {
      case OrganizationActionType.GET_ORGANIZATION: {
        draft.organizationStatus = DataLoadingStatus.LOADING;
        draft.organizationError = undefined;
        break;
      }
      case OrganizationActionType.GET_ORGANIZATION_COMPLETED: {
        draft.organizationStatus = DataLoadingStatus.LOADED;
        draft.organization = action.payload;
        break;
      }
      case OrganizationActionType.GET_ORGANIZATION_ERROR: {
        draft.organizationStatus = DataLoadingStatus.ERROR;
        draft.organizationError = action.payload;
        break;
      }
      case OrganizationActionType.UPDATE_ORGANIZATION: {
        draft.updateOrganizationStatus = DataLoadingStatus.LOADING;
        draft.updateOrganizationError = undefined;
        break;
      }
      case OrganizationActionType.UPDATE_ORGANIZATION_COMPLETED: {
        draft.updateOrganizationStatus = DataLoadingStatus.LOADED;
        draft.organization = action.payload;
        break;
      }
      case OrganizationActionType.UPDATE_ORGANIZATION_ERROR: {
        draft.updateOrganizationStatus = DataLoadingStatus.ERROR;
        draft.updateOrganizationError = action.payload;
        break;
      }
      case OrganizationActionType.CLEAR_UPDATE_STATUS: {
        draft.updateOrganizationStatus = DataLoadingStatus.INITIAL;
        draft.updateOrganizationError = undefined;
        break;
      }
      case OrganizationActionType.UPSERT_HOLIDAY_LIST_COMPLETED: {
        draft.upsertHolidayListSuccess = action.payload;
        break;
      }
      case OrganizationActionType.UPSERT_HOLIDAY_LIST_ERROR: {
        draft.upsertHolidayListError = action.payload;
        draft.upsertHolidayListSuccess = false;
        break;
      }
      case OrganizationActionType.LIST_HOLIDAY_LISTS_COMPLETED: {
        draft.holidayLists = action.payload;
        draft.listHolidayListsError = undefined;
        break;
      }
      case OrganizationActionType.LIST_HOLIDAY_LISTS_ERROR: {
        draft.listHolidayListsError = action.payload;
        break;
      }
      case OrganizationActionType.GET_SINGLE_HOLIDAY_COMPLETED: {
        draft.singleHolidayList = action.payload;
        draft.getSingleHolidayListError = undefined;
        break;
      }
      case OrganizationActionType.GET_SINGLE_HOLIDAY_ERROR: {
        draft.getSingleHolidayListError = action.payload;
        draft.singleHolidayList = undefined;
        break;
      }
      case OrganizationActionType.GET_SINGLE_HOLIDAY_IS_LOADING: {
        draft.singleHolidayListLoading = action.payload;
        break;
      }
      case OrganizationActionType.DELETE_HOLIDAY_LIST_COMPLETED: {
        draft.deleteHolidayListSuccess = action.payload;
        break;
      }
      case OrganizationActionType.DELETE_HOLIDAY_LIST_ERROR: {
        draft.deleteHolidayListError = action.payload;
        draft.deleteHolidayListSuccess = false;
        break;
      }
      case OrganizationActionType.DELETE_HOLIDAY_LIST_LOADING: {
        draft.deleteHolidayListLoading = action.payload;
        break;
      }
      case OrganizationActionType.UPSERT_HOLIDAY_LIST_LOADING: {
        draft.upsertHolidayListLoading = action.payload;
        break;
      }
      default:
        break;
    }
  });
