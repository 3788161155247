import { ApplicationName } from './protos/enums';
import {
  DocClassificationParam,
  EntityDataType,
  EntityDetails,
  EntityTypeSchema,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  Workflow,
  WorkflowLearningSettings,
  WorkflowMode,
} from 'protos/pb/v1alpha2/workflows_service';
import {
  CLASSIFICATION_SCHEMA_DOCUMENT_TYPE,
  FileName,
  MAX_NESTED_ENTITIES,
  MAX_SIMPLE_ENTITIES,
  ORBYAI_UNKNOWN,
} from './constants';
import {
  checkIfTypeMatches,
  hasInvalidCharacters,
  isInvalidNotesEntity,
} from './entities';
import {
  allowParentNamePrefixForGoogleOrgs,
  isChildPrefixedWithParentName,
  trimParentNamePrefix,
} from './helpers';
import store from '../redux/store';
import { cloneDeep } from 'lodash';
import {
  invalidCharactersClassificationMsg,
  invalidNotesMsg,
  invalidCharactersMsg,
  invalidCharactersOptionMsg,
  incorrectTypeForNotesMsg,
  entityAlreadyExistsMsg,
  childEntityAlreadyExistMsg,
  invalidFileNameMsg,
} from './errorMessages/entity';
import {
  ReviewerList,
  WorkflowUser,
  ReviewTriggerCondition,
  ConditionType,
  WorkflowAssignmentOption,
  Operator,
} from 'protos/common/review';

const reviewerListRequiredModes = [WorkflowMode.DEFAULT, WorkflowMode.ASSISTED];
const validWorkflowModes = [
  WorkflowMode.DEFAULT,
  WorkflowMode.ASSISTED,
  WorkflowMode.AUTOPILOT,
];

// Function to validate learning settings.
const validateLearningSettings = (
  learningSettings?: WorkflowLearningSettings,
  reviewerList: ReviewerList[] = [],
) => {
  // Return early if reviewer list is empty
  if (!reviewerList?.length) {
    return;
  }

  if (!learningSettings) {
    throw new Error(
      'Validation error: The "learningSettings" key is required but missing.',
    );
  }

  if (!learningSettings?.reviewers?.length) {
    throw new Error(
      'Validation Error: The "reviewers" list within "learningSettings" must contain at least one reviewer.',
    );
  }

  // Check for duplicate reviewers in the learningSettings reviewers list
  const duplicateUser = learningSettings?.reviewers.find((user, index) =>
    (learningSettings?.reviewers || []).some(
      (u, i) => i !== index && u.reviewer?.username === user.reviewer?.username,
    ),
  );

  if (duplicateUser) {
    throw new Error(
      `Validation Error: The "learningSettings" contains a duplicate reviewer: "${duplicateUser.reviewer?.username}".`,
    );
  }

  // Create a set of all reviewer emails from the reviewerList
  const allReviewers = new Set<string>();
  reviewerList.forEach((round) => {
    round.users?.forEach((user) => allReviewers.add(user?.user as string));
    round.assignmentOption?.conditionalAssignment?.forEach((info) => {
      info.users?.forEach((user) => allReviewers.add(user?.user as string));
    });
    round.assignmentOption?.manualAssignment?.users?.forEach((info) => {
      allReviewers.add(info?.user as string);
    });
  });

  // Check if any reviewer exists in the reviewerList but has existsInReviewerList set to false in learningSettings
  const currentReviewerExistAsPastReviewer = learningSettings?.reviewers.find(
    (u) =>
      !u.existsInReviewerList && allReviewers.has(u.reviewer?.username || ''),
  );

  if (currentReviewerExistAsPastReviewer) {
    throw new Error(
      `Validation Error: Reviewer "${currentReviewerExistAsPastReviewer?.reviewer?.username}" exists in the "reviewerLists" but has "existsInReviewerList" is set to false.`,
    );
  }

  // Check if any reviewer does not exist in the reviewerList but has existsInReviewerList set to true in learningSettings
  const userAbsentWithTrueFlag = learningSettings?.reviewers.find(
    (u) =>
      u.existsInReviewerList && !allReviewers.has(u.reviewer?.username || ''),
  );

  if (userAbsentWithTrueFlag) {
    throw new Error(
      `Validation Error: Reviewer "${userAbsentWithTrueFlag?.reviewer?.username}" absent in the "reviewerLists" but has "existsInReviewerList" set to true.`,
    );
  }

  // Check if any reviewer is missing in the learningSettings reviewers list but exists in the reviewerList
  const userMissingInSettings = [...allReviewers].find(
    (email) =>
      !learningSettings?.reviewers?.some((r) => r.reviewer?.username === email),
  );

  if (userMissingInSettings) {
    throw new Error(
      `Validation Error: Reviewer "${userMissingInSettings}" exists in the "reviewerLists" but absent in "learningSettings".`,
    );
  }
};

export const validateYamlWorkflow = (workflow: Workflow) => {
  const workflowMode: WorkflowMode = WorkflowMode[
    workflow.mode!
  ] as unknown as WorkflowMode;

  if (workflowMode !== WorkflowMode.AUTOPILOT) {
    // Validate learning settings
    validateLearningSettings(
      workflow?.learningSettings,
      workflow?.reviewerLists,
    );
  }
  // Rule 1
  if (!workflow.templateResourceName) {
    throw new Error('Validation error: templateResourceName is required.');
  }

  // Rule 2
  if (!workflow.displayName) {
    throw new Error('Validation error: displayName is required.');
  }

  // Rule 3
  if (!('description' in workflow)) {
    throw new Error('Validation error: description is required.');
  }

  // Rule 4
  if (!('manualTimeCostInMinutes' in workflow)) {
    throw new Error('Validation error: manualTimeCostInMinutes is required.');
  }
  if (
    workflow.manualTimeCostInMinutes! <= 0 ||
    !Number.isInteger(workflow.manualTimeCostInMinutes)
  ) {
    throw new Error(
      'Validation error: manualTimeCostInMinutes should be a positive integer.',
    );
  }

  // Rule 5
  if (!('mode' in workflow)) {
    throw new Error('Validation error: mode is required.');
  }
  if (!validWorkflowModes.includes(workflowMode)) {
    throw new Error(
      'Validation error: mode should have one of the values - DEFAULT, ASSISTED, AUTOPILOT.',
    );
  }

  // Rule 6
  if (!workflow.steps) {
    throw new Error('Validation error: steps is required.');
  }

  // Rule 7
  if (reviewerListRequiredModes.includes(workflowMode)) {
    if (!workflow.reviewerLists || !Array.isArray(workflow.reviewerLists)) {
      throw new Error(
        'Validation error: reviewerLists is required for DEFAULT and ASSISTED modes.',
      );
    }
  } else if (workflow.reviewerLists) {
    throw new Error(
      'Validation error: reviewerLists should not be present for AUTOPILOT mode.',
    );
  }

  // Rule 8a
  workflow.steps.forEach((step) => {
    if (!step.actions || step.actions.length !== 1) {
      throw new Error(
        'Validation error: actions is required and should have a length of 1.',
      );
    }
  });

  // Rule 8b
  workflow.steps.forEach((step) => {
    step.actions!.forEach((action) => {
      if (action.application === ApplicationName.EntityExtraction) {
        const schemaMappings =
          action?.entityExtraction?.entityTypeSchemaMapping;
        if (!action.entityExtraction || !schemaMappings) {
          throw new Error(
            'Validation error: entityTypeSchemaMapping is required for Entity Extraction.',
          );
        }
        if (
          Object.keys(schemaMappings).filter(
            (entity) =>
              entity.toLowerCase() !== FileName &&
              entity.split('/').length === 1 &&
              ![
                EntityDataType[EntityDataType.ENTITY_TYPE_NESTED],
                EntityDataType[EntityDataType.ENTITY_TYPE_ANNOTATION],
              ].includes(
                schemaMappings[entity].normalizationType as unknown as string,
              ),
          ).length > MAX_SIMPLE_ENTITIES
        ) {
          throw new Error(
            `Validation error: entityTypeSchemaMapping should have a maximum of ${MAX_SIMPLE_ENTITIES} normal entities.`,
          );
        }
        if (
          Object.keys(schemaMappings).filter(
            (entity) =>
              !checkIfTypeMatches(
                schemaMappings[entity].normalizationType!,
                EntityDataType.ENTITY_TYPE_ANNOTATION,
              ) && entity.split('/').length > 1,
          ).length > MAX_NESTED_ENTITIES
        ) {
          throw new Error(
            `Validation error: entityTypeSchemaMapping should have a maximum of ${MAX_NESTED_ENTITIES} nested entities.`,
          );
        }
        validateSftpGdriveEntityTypeSchemaMapping(
          schemaMappings,
          action.entityExtraction.entitiesDetails!,
        );
      } else if (
        action.application === ApplicationName.DocumentClassification
      ) {
        validateClassification(action.classification!);
      } else {
        throw new Error(
          'Validation error: application should be Entity Extraction or Document Classification.',
        );
      }
    });
  });

  // Rule 9
  if (
    reviewerListRequiredModes.includes(workflowMode) &&
    workflow.reviewerLists
  ) {
    workflow.reviewerLists.forEach((list) => {
      if (
        (!list.users || list.users.length === 0) &&
        !hasManualAssignmentMode(list)
      ) {
        throw new Error(
          'Validation error: users should be present and not empty.',
        );
      }

      if (list.roundNumber === 2 && workflow.reviewerLists!.length !== 2) {
        throw new Error(
          'Validation error: roundNumber can only be 2 if reviewerLists length is 2.',
        );
      }
      validateTriggerCondition(
        list.triggerCondition!,
        list.roundNumber!,
        workflowMode,
        workflow,
      );
      validateEmailLowerCase(list.users!);
      validateAssignmentOption(list?.assignmentOption);
    });
    /**
     * If reviewerLists length is 2, and if roundNumber 1 and 2 only have 1 user each then the users should be different.
     */
    if (workflow.reviewerLists.length === 2) {
      const round1Users = workflow.reviewerLists[0].users!;
      const round2Users = workflow.reviewerLists[1].users!;
      if (
        round1Users.length === 1 &&
        round2Users.length === 1 &&
        round1Users[0].user === round2Users[0].user
      ) {
        throw new Error(
          'Validation error: users in roundNumber 1 and 2 should be different if both only have 1 user.',
        );
      }
    }
  }
};

const hasManualAssignmentMode = (reviewerList: ReviewerList) => {
  if (
    !reviewerList.assignmentOption ||
    !reviewerList.assignmentOption.manualAssignment
  )
    return false;
  return (
    (reviewerList.assignmentOption.manualAssignment.users || []).length > 0
  );
};

const validateEmailLowerCase = (users: WorkflowUser[]) => {
  for (const user of users) {
    if (!user.user) {
      throw new Error('Validation error: user is required.');
    }
    if (user.user !== user.user.toLowerCase()) {
      throw new Error(
        `Validation error: user "${user.user}" should be in lowercase.`,
      );
    }
  }
};

// Function to return duplicate entity from entitiesDetails
const getDuplicateEntity = (entitiesDetails: EntityDetails[]) => {
  const visitedEntities = new Set();
  for (const entity of entitiesDetails) {
    if (visitedEntities.has(entity.entityType?.toString().toLowerCase())) {
      return entity;
    } else {
      visitedEntities.add(entity.entityType?.toString().toLowerCase());
    }
  }
  return undefined;
};

const validateEntityName = (entityName: string, isOption = false) => {
  // Handle invalid notes entity
  if (isOption) {
    if (hasInvalidCharacters(entityName, true)) {
      throw new Error(invalidCharactersOptionMsg({ name: entityName }));
    }
    return;
  }
  if (isInvalidNotesEntity(entityName)) {
    throw new Error(invalidNotesMsg({ name: entityName }));
  }
  if (hasInvalidCharacters(entityName)) {
    throw new Error(invalidCharactersMsg({ name: entityName }));
  }
};

const validateFileNameEntity = (entityDetails?: EntityDetails) => {
  if (
    !entityDetails ||
    !entityDetails.entityType ||
    !checkIfTypeMatches(
      entityDetails.normalizationType!,
      EntityDataType.ENTITY_TYPE_UNSPECIFIED,
    )
  ) {
    throw new Error(invalidFileNameMsg);
  }
};

const validateNotesEntity = (entityDetails: EntityDetails) => {
  const isInvalidEntity =
    entityDetails.entityType?.toString().startsWith('*') &&
    !checkIfTypeMatches(
      entityDetails.normalizationType!,
      EntityDataType.ENTITY_TYPE_ANNOTATION,
    );

  if (isInvalidEntity) {
    throw new Error(
      incorrectTypeForNotesMsg({
        name: entityDetails.entityType!,
        type: entityDetails.normalizationType as any as string,
        isYAML: true,
      }),
    );
  }
};

const validateChoiceTypeEntity = (entity: EntityDetails) => {
  // handle choice type entity
  if (
    checkIfTypeMatches(
      entity.normalizationType!,
      EntityDataType.ENTITY_TYPE_CHOICE,
    )
  ) {
    if (!entity.properties?.length) {
      throw new Error(
        `Entity "${entity.entityType}" with normalization type "${EntityDataType[EntityDataType.ENTITY_TYPE_CHOICE]}" should have at least one child with normalization type "${EntityDataType[EntityDataType.ENTITY_TYPE_CHOICE_OPTION]}"`,
      );
    }
    entity.properties?.forEach((optionEntity) => {
      validateEntityName(optionEntity.entityType!.toString(), true);
      if (
        !checkIfTypeMatches(
          optionEntity.normalizationType!,
          EntityDataType.ENTITY_TYPE_CHOICE_OPTION,
        )
      ) {
        throw new Error(
          `Entity "${entity.entityType}" with normalization type "${EntityDataType[EntityDataType.ENTITY_TYPE_CHOICE]}" supports only "${EntityDataType[EntityDataType.ENTITY_TYPE_CHOICE_OPTION]}" as normalization type for its children, but found a child "${optionEntity.entityType}" with normalization type "${optionEntity.normalizationType}"`,
        );
      }
    });

    // handle duplicate entities
    const duplicateNestedEntity = getDuplicateEntity(entity.properties!);
    if (duplicateNestedEntity) {
      throw new Error(
        childEntityAlreadyExistMsg({
          child: duplicateNestedEntity.entityType!,
          parent: entity.entityType!,
        }),
      );
    }
  }
};

const validateSftpGdriveEntityTypeSchemaMapping = (
  entityTypeSchemaMapping: Record<string, EntityTypeSchema>, // FOR BACKWARDS COMPATIBILITY
  entitiesDetails: EntityDetails[],
) => {
  const ALLOW_PARENT_NAME_PREFIX = allowParentNamePrefixForGoogleOrgs(
    store?.getState()?.user.selectedOrgInfo?.orgResourceName ?? '',
  );
  if (entitiesDetails?.length) {
    if (ALLOW_PARENT_NAME_PREFIX) {
      entitiesDetails = cloneDeep(entitiesDetails);
      const parentEntities = entitiesDetails.filter(
        (e) =>
          (EntityDataType[
            e.normalizationType!
          ] as unknown as EntityDataType) === EntityDataType.ENTITY_TYPE_NESTED,
      );
      if (
        entitiesDetails.filter(
          (entityDetail) =>
            entityDetail.entityType?.toString() !== FileName &&
            ![
              EntityDataType[EntityDataType.ENTITY_TYPE_NESTED],
              EntityDataType[EntityDataType.ENTITY_TYPE_ANNOTATION],
            ].includes(entityDetail.normalizationType as unknown as string),
        ).length > MAX_SIMPLE_ENTITIES
      ) {
        throw new Error(
          `Validation error: entitiesDetails should have a maximum of ${MAX_SIMPLE_ENTITIES} normal entities.`,
        );
      }

      const childEntities: EntityDetails[] = [];
      parentEntities.forEach((entityDetail) => {
        entityDetail.properties!.forEach((details) => {
          if (
            (details.normalizationType as unknown as string) !==
            EntityDataType[EntityDataType.ENTITY_TYPE_ANNOTATION]
          ) {
            childEntities.push(entityDetail);
          }
        });
      });

      if (childEntities.length > MAX_NESTED_ENTITIES) {
        throw new Error(
          `Validation error: entitiesDetails should have a maximum of ${MAX_NESTED_ENTITIES} nested entities.`,
        );
      }
      parentEntities.forEach((entity) => {
        const parentName = entity.entityType!;
        if (entity.properties!.length) {
          const isPrefixedEntityExpected = isChildPrefixedWithParentName(
            entity.properties![0].entityType!,
            parentName,
          );
          if (isPrefixedEntityExpected) {
            for (const childEntity of entity.properties!) {
              if (
                !isChildPrefixedWithParentName(
                  childEntity.entityType!,
                  parentName,
                )
              ) {
                throw new Error(
                  `Validation error: Parent Entity "${parentName}" must have all child entities either prefixed with "${parentName}/" or none of them should be prefixed with "${parentName}/".`,
                );
              } else {
                childEntity.entityType = trimParentNamePrefix(
                  childEntity.entityType!,
                );
              }
            }
          } else {
            for (const childEntity of entity.properties!) {
              if (
                isChildPrefixedWithParentName(
                  childEntity.entityType!,
                  parentName,
                )
              ) {
                throw new Error(
                  `Validation error: Parent Entity "${parentName}" must have all child entities either prefixed with "${parentName}/" or none of them should be prefixed with "${parentName}/".`,
                );
              }
            }
          }
        }
      });
    }

    // Validate entitiesDetails should have "file name" and its normalizationType should be ENTITY_TYPE_TEXT
    const fileNameEntity = entitiesDetails.find(
      (e) => e?.entityType?.toString() === FileName,
    );
    validateFileNameEntity(fileNameEntity);

    // Handle file name as nested entity case
    const isFileNameInChild = entitiesDetails.find((e) =>
      e.properties?.some(
        (p) => p.entityType?.toString().toLowerCase() === FileName,
      ),
    );
    if (isFileNameInChild) {
      throw new Error(
        `Validation error: The entity "${isFileNameInChild.entityType}" should not have "file name" as a child entity.`,
      );
    }
    // Handle duplicate entity case
    const duplicateEntity = getDuplicateEntity(entitiesDetails);
    if (duplicateEntity) {
      throw new Error(
        entityAlreadyExistsMsg({ name: duplicateEntity.entityType! }),
      );
    }
    // Handle duplicate nested entity case
    const duplicateNestedEntity = entitiesDetails.find((e) =>
      getDuplicateEntity(e.properties!),
    );
    if (duplicateNestedEntity) {
      throw new Error(
        childEntityAlreadyExistMsg({
          parent: duplicateNestedEntity.entityType!,
          child: getDuplicateEntity(duplicateNestedEntity.properties!)!
            .entityType!,
        }),
      );
    }
    // Handle invalid entities
    entitiesDetails.forEach((entity) => {
      // validate normal entities
      validateChoiceTypeEntity(entity);
      validateEntityName(entity.entityType!.toString());
      validateNotesEntity(entity);

      // validate child entities
      if (
        checkIfTypeMatches(
          entity.normalizationType!,
          EntityDataType.ENTITY_TYPE_NESTED,
        ) &&
        entity.properties?.length
      ) {
        entity.properties.forEach((child) => {
          validateChoiceTypeEntity(child);
          validateEntityName(child.entityType!.toString());
          validateNotesEntity(child);
        });
      }
    });
  } else {
    for (const entityType in entityTypeSchemaMapping) {
      const entity = entityType.toString();
      // Validate entity should be in lowercase
      if (entity !== entity.toLowerCase()) {
        throw new Error(
          `Validation error: entity "${entity}" should be in lowercase.`,
        );
      }
      const mapping = entityTypeSchemaMapping[entity];
      if (!mapping.normalizationType) {
        throw new Error(
          `Validation error: normalizationType is required for "${entity}"`,
        );
      }
      // If an entity type starts with "*", its normalization type needs to be the ENTITY_TYPE_ANNOTATION
      if (
        !Object.values(EntityDataType).includes(
          EntityDataType[mapping.normalizationType],
        )
      ) {
        throw new Error(
          `Validation error: normalizationType is invalid for "${entity}"`,
        );
      }
      const splitEntity = entity.split('/');
      // Handle invalid entity for parent and child
      splitEntity.forEach((e) => {
        // Check if parent entity includes "*" symbol
        if (entity.split('/').length === 2 && splitEntity[0].includes('*')) {
          throw new Error(
            `Entity "${entity}": Parent entity name cannot contain "*" symbol.`,
          );
        }
        // Handle invalid notes entity
        if (isInvalidNotesEntity(entity)) {
          throw new Error(invalidNotesMsg({ name: entity }));
        }
        // Handle invalid characters in entity
        if (hasInvalidCharacters(e)) {
          throw new Error(invalidCharactersMsg({ name: entity }));
        }
      });
    }
    // Validate entityTypeSchemaMapping should have "file name" and its normalizationType should be ENTITY_TYPE_TEXT
    const fileName = entityTypeSchemaMapping[FileName];
    validateFileNameEntity(
      EntityDetails.create({
        entityType: fileName ? FileName : undefined,
        normalizationType: fileName.normalizationType,
      }),
    );
    // If an entity starts with "*", its normalization type needs to be the ENTITY_TYPE_ANNOTATION
    Object.keys(entityTypeSchemaMapping).forEach((e) => {
      validateNotesEntity(
        EntityDetails.create({
          entityType: e,
          normalizationType: entityTypeSchemaMapping[e].normalizationType!,
        }),
      );
    });

    // Handle file name as nested entity case for old workflow object type
    const parentWithFileName = Object.keys(entityTypeSchemaMapping).find(
      (e) => e.split('/')?.[1]?.toLowerCase() === FileName,
    );
    if (parentWithFileName) {
      throw new Error(
        `Validation error: The entity "${parentWithFileName}" should not have "file name" as a child entity.`,
      );
    }
  }
};

const validateClassification = (classification: DocClassificationParam) => {
  if (
    !classification ||
    !classification.classificationLabels ||
    classification.classificationLabels.length === 0
  ) {
    throw new Error(
      'Validation error: classificationLabels is required and should not be empty.',
    );
  }
  if (classification.classificationLabels.length > 8) {
    throw new Error(
      'Validation error: classificationLabels should have a maximum of 8 labels.',
    );
  }
  // Validate classificationLabels should be in lowercase
  for (const label of classification.classificationLabels) {
    if (label !== label.toLowerCase()) {
      throw new Error(
        `Validation error: classificationLabel "${label}" should be in lowercase.`,
      );
    }
  }
  // Validate classificationLabels should not have "OrbyAI_Unknown"
  const orbyAiUnknown = classification.classificationLabels.find(
    (label) => label.toLowerCase() === ORBYAI_UNKNOWN.toLowerCase(),
  );
  if (orbyAiUnknown) {
    throw new Error(
      `Validation error: "${ORBYAI_UNKNOWN}" doesn't need to be specified in classificationLabels.`,
    );
  }

  const duplicateLabel = classification.classificationLabels.find(
    (label: string, index: number) =>
      classification.classificationLabels!.some(
        (otherLabel: string, otherIndex: number) =>
          index !== otherIndex &&
          label.toLowerCase() === otherLabel.toLowerCase(),
      ),
  );
  if (duplicateLabel) {
    throw new Error(
      `Validation error: classificationLabel "${duplicateLabel}" already exists..`,
    );
  }
  const invalidLabel = classification.classificationLabels.find(
    (label: string) => hasInvalidCharacters(label, true),
  );
  if (invalidLabel) {
    throw new Error(
      `Validation error: "${invalidLabel}" ${invalidCharactersClassificationMsg}`,
    );
  }
};

const validateTriggerCondition = (
  triggerCondition: ReviewTriggerCondition,
  roundNumber: number,
  workflowMode: WorkflowMode,
  workflow: Workflow,
) => {
  // Throw error if triggerCondition is empty
  if (!triggerCondition) {
    throw new Error(
      'Validation error: triggerCondition is required and cannot be empty.',
    );
  }

  const conditionType = ConditionType[
    triggerCondition.conditionType!
  ] as unknown as ConditionType;
  if (roundNumber === 1) {
    if (workflowMode === WorkflowMode.DEFAULT) {
      if (conditionType !== ConditionType.UNSPECIFIED) {
        throw new Error(
          'Validation error: conditionType should be UNSPECIFIED in roundNumber 1 for DEFAULT mode.',
        );
      }
      if (triggerCondition.conditionOptions) {
        throw new Error(
          'Validation error: conditionOptions should not be present in roundNumber 1 for DEFAULT mode.',
        );
      }
      if (workflow.needAttentionThresholdDefaultMode === undefined) {
        throw new Error(
          'Validation error: confidenceScore is required for DEFAULT mode.',
        );
      }
      const confidenceScore = Number(
        workflow.needAttentionThresholdDefaultMode,
      );
      if (confidenceScore < 0 || confidenceScore > 1) {
        throw new Error(
          'Validation error: confidenceScore should be between 0 and 1 for DEFAULT mode.',
        );
      }
      if (!Number.isInteger(workflow.needAttentionThresholdDefaultMode * 100)) {
        throw new Error(
          'Validation error: Validation error: entities that need attention in DEFAULT mode require an integer confidence score threshold.',
        );
      }
    } else if (workflowMode === WorkflowMode.ASSISTED) {
      if (conditionType === ConditionType.UNSPECIFIED) {
        throw new Error(
          'Validation error: conditionType should not be UNSPECIFIED for ASSISTED mode.',
        );
      }
      if (!Object.values(ConditionType).includes(conditionType)) {
        throw new Error(
          'Validation error: conditionType should be one of the specified values in roundNumber 1 for ASSISTED mode.',
        );
      }
      if (
        [
          ConditionType.ANY_EXTRACTED_FIELD,
          ConditionType.AVERAGE_CONFIDENCE_SCORE,
        ].includes(conditionType)
      ) {
        if (!triggerCondition.conditionOptions) {
          throw new Error(
            'Validation error: conditionOptions is required in roundNumber 1 for ASSISTED mode.',
          );
        }
        if (
          !Number.isInteger(triggerCondition.conditionOptions.confidenceScore)
        ) {
          throw new Error(
            'Validation error: confidenceScore should be an integer in roundNumber 1 for ASSISTED mode.',
          );
        }
        const confidenceScore = parseInt(
          triggerCondition.conditionOptions.confidenceScore!.toString(),
        );
        if (confidenceScore < 0 || confidenceScore > 100) {
          throw new Error(
            'Validation error: confidenceScore should be between 0 and 100 in roundNumber 1 for ASSISTED mode.',
          );
        }
      } else if (conditionType === ConditionType.ANY_EMPTY_PREDICTIONS) {
        if (triggerCondition.conditionOptions) {
          throw new Error(
            'Validation error: conditionOptions should not be present in roundNumber 1 for ASSISTED mode.',
          );
        }
      } else if (conditionType === ConditionType.SPECIFIC_EXTRACTED_FIELD) {
        if (!triggerCondition.conditionOptions) {
          throw new Error(
            'Validation error: conditionOptions is required in roundNumber 1 for ASSISTED mode.',
          );
        }
        if (
          !triggerCondition.conditionOptions.groupCondition ||
          !triggerCondition.conditionOptions.groupCondition.conditions ||
          triggerCondition.conditionOptions.groupCondition.conditions.length ===
            0
        ) {
          throw new Error(
            'Validation error: conditions should be present and not empty in roundNumber 1 for ASSISTED mode.',
          );
        }
        // If workflow application is Entity Extraction, validate that the conditions present in triggerCondition should also be present in entityTypeSchemaMapping
        if (
          workflow.steps![0].actions![0].application ===
          ApplicationName.EntityExtraction
        ) {
          const entitiesDetails =
            workflow.steps![0].actions![0].entityExtraction?.entitiesDetails;

          const entityTypeSchemaMapping =
            workflow.steps![0].actions![0].entityExtraction!
              .entityTypeSchemaMapping!;
          for (const condition of triggerCondition.conditionOptions
            .groupCondition.conditions) {
            if (
              !entitiesDetails?.length &&
              !entityTypeSchemaMapping[condition.attribute!]
            ) {
              throw new Error(
                `Validation error: condition "${condition.attribute}" is not present in entityTypeSchemaMapping.`,
              );
            }
          }
        }

        if (
          workflow.steps![0].actions![0].application ===
          ApplicationName.DocumentClassification
        ) {
          if (
            triggerCondition.conditionOptions.groupCondition.conditions
              .length !== 1
          ) {
            throw new Error(
              'Validation error: conditions should have a length of 1 in roundNumber 1 for ASSISTED mode in Classification Workflow.',
            );
          }
          const label =
            triggerCondition.conditionOptions.groupCondition.conditions[0];
          //eslint-disable-next-line
          const regex = /[a-zA-Z!@#\$%\^\&*\)\(+=._-]/;
          // Check if value contains any other characters rather than numeric
          if (regex.test(label.value || '')) {
            throw new Error(
              'Validation error: classificationLabels value should contain only numeric characters.',
            );
          }
          const value = label.value ? parseInt(label.value) : undefined;
          if (!value) {
            throw new Error(
              'Validation error: classificationLabels should be an integer in roundNumber 1 for ASSISTED mode in Classification Workflow.',
            );
          }
          if (
            label.attributeType?.name !== CLASSIFICATION_SCHEMA_DOCUMENT_TYPE ||
            value < 0 ||
            value > 100
          ) {
            throw new Error(
              'Validation error: classificationLabels have invalid values.',
            );
          }
        }
      }
    }
  } else if (roundNumber === 2) {
    if (conditionType !== ConditionType.RANDOM_SAMPLE_PERCENT) {
      throw new Error(
        'Validation error: conditionType should be RANDOM_SAMPLE_PERCENT in roundNumber 2.',
      );
    }
    if (!triggerCondition.conditionOptions) {
      throw new Error(
        'Validation error: conditionOptions should be present and not empty in roundNumber 2.',
      );
    }
    if (
      !Number.isInteger(triggerCondition.conditionOptions.percentOfRandomSample)
    ) {
      throw new Error(
        'Validation error: percentOfRandomSample should be an integer in roundNumber 2.',
      );
    }
    const percentOfRandomSample = parseInt(
      triggerCondition.conditionOptions.percentOfRandomSample!.toString(),
    );
    if (percentOfRandomSample < 0 || percentOfRandomSample > 100) {
      throw new Error(
        'Validation error: percentOfRandomSample should be between 0 and 100 in roundNumber 2.',
      );
    }
  } else {
    throw new Error(
      'Validation error: roundNumber should have a value of 1 or 2.',
    );
  }
};

const validateAssignmentOption = (
  assignmentOptions?: WorkflowAssignmentOption,
) => {
  if (assignmentOptions) {
    assignmentOptions.conditionalAssignment?.forEach((cAssignment) => {
      const condition = cAssignment?.groupCondition?.conditions?.[0];
      if (!condition?.attributeType?.name) {
        throw new Error(
          'Validation error: attributeType name is missing in conditions for conditionalAssignment',
        );
      }
      if (!condition?.operator) {
        throw new Error(
          'Validation error: operator is missing in conditions for conditionalAssignment',
        );
      }
      if (
        ![Operator.EXISTS, Operator.DOES_NOT_EXIST].includes(
          condition?.operator,
        ) &&
        !condition?.value
      ) {
        throw new Error(
          'Validation error: Operators except EXISTS or DOES_NOT_EXIST should have value in conditions for conditionalAssignment',
        );
      }
    });
  }
};
