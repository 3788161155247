import produce from 'immer';
import { Reducer } from 'redux';
import { AutomationMiningActionType } from '../actions/actions.constants';
import { DataLoadingStatus } from '../../utils/constants';

export interface AutomationMiningState {
  mimeType: string;
  activityGraph: string;
  loadingActivityGraphStatus: DataLoadingStatus;
}

const initialState: AutomationMiningState = {
  mimeType: '',
  activityGraph: '',
  loadingActivityGraphStatus: DataLoadingStatus.INITIAL,
};

export const automationMiningReducer: Reducer<AutomationMiningState> = (
  state: AutomationMiningState = initialState,
  action,
) =>
  produce(state, (draft: AutomationMiningState) => {
    switch (action.type) {
      case AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH: {
        draft.loadingActivityGraphStatus = DataLoadingStatus.LOADING;
        break;
      }
      case AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH_COMPLETED: {
        draft.mimeType = action.payload.mimeType;
        draft.activityGraph = action.payload.activityGraph;
        draft.loadingActivityGraphStatus = DataLoadingStatus.LOADED;
        break;
      }
      case AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH_ERROR:
        draft.mimeType = '';
        draft.activityGraph = '';
        draft.loadingActivityGraphStatus = DataLoadingStatus.ERROR;
        break;
      default:
        break;
    }
  });
