import { Box, Tooltip } from '@mui/material';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DRAWER_TABS } from './drawerTabs';
import orbyLogo from '../../static/orby-white.svg';
import orbyIcon from '../../static/o-white.svg';
import DrawerTab from './DrawerTab';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';
import { isAdmin, isOrbyAIUser } from '../../utils/helpers';
import SkipLink from './SkipLink';
import {
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  FEATURE_FLAGS,
} from '../../utils/constants';
import { OrbyColorPalette } from 'orby-ui/src';
import DrawerFooter from './DrawerFooter';
import { Link } from 'react-router-dom';
import {
  getFontSize,
  getFontWeight,
} from 'orby-ui/src/components/typography/typography-utils';
import useIsFeatureEnabled from '../../hooks/useIsFeatureEnabled';
import { APP_ROUTES } from '../../pages/ProtectedRoutes/Routes';
import { amplitudeService } from '../../services/AmplitudeService';
import { routerPathSelector } from '../../redux/slices/router.slice';

interface Props {
  collapse: boolean;
  setCollapse: (val: boolean) => void;
  orbotWorkflowLength: number;
}

const WebDrawerWrapper: FC<Props> = ({
  collapse,
  setCollapse,
  orbotWorkflowLength,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector(loggedInUserSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const routerPath = useSelector(routerPathSelector);

  const isOrbyAdmin =
    selectedOrgInfo?.orgDisplayName === 'Orby AI' &&
    isAdmin(selectedOrgInfo?.role);
  const selectedPath = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  const isRbacEnabled = useIsFeatureEnabled(FEATURE_FLAGS.RBAC);
  const userPermissions = useSelector(userPermissionsSelector);
  const canAccessWorkflowPage =
    isAdmin(selectedOrgInfo?.role) ||
    orbotWorkflowLength ||
    (isRbacEnabled &&
      (userPermissions.createWorkflow ||
        userPermissions.listWorkflows ||
        userPermissions.listConnectors));

  const canAccessExecutionPage =
    isAdmin(selectedOrgInfo?.role) ||
    orbotWorkflowLength ||
    (isRbacEnabled && userPermissions.listWorkflows);

  const handleDrawerItemClick = useCallback(
    (path: string) => navigate(path),
    [],
  );

  const [logo, setLogo] = useState(orbyLogo);

  // This is to prevent the logo from flashing when collapsing and expanding
  useEffect(() => {
    let selectedLogo = orbyLogo;
    if (collapse) {
      selectedLogo = orbyIcon;
    }
    setTimeout(() => {
      setLogo(selectedLogo);
    }, 10);
  }, [collapse]);

  const renderHeader = useMemo(() => {
    return (
      <Box display='flex' justifyContent={'center'}>
        <Link to={APP_ROUTES.DASHBOARD}>
          <img
            style={{
              marginTop: '32px',
              marginBottom: '44px',
            }}
            src={logo}
            alt='Orby Logo'
          />
        </Link>
      </Box>
    );
  }, [collapse, logo]);

  const checkForTabPermissions = (title: string) => {
    if (!isRbacEnabled) {
      if (['Teams', 'Deleted Items', 'Settings', 'Trash Can'].includes(title)) {
        // UPDATE ON src/pages/OptionsConfig.tsx too
        return isAdmin(selectedOrgInfo?.role);
      } else if (['Workflows', 'Workflow'].includes(title)) {
        return canAccessWorkflowPage;
      } else if (['Executions'].includes(title)) {
        return canAccessExecutionPage;
      } else if (title === 'ML Testing') {
        return isOrbyAIUser(user);
      } else if (title === 'Integration Test') {
        return user?.email === process.env.REACT_APP_TEST_USER;
      } else if (title === 'Feature Flags') {
        return isOrbyAdmin;
      }
      return true;
    } else {
      if (title === 'Teams') {
        return isAdmin(selectedOrgInfo?.role) || userPermissions.updateUser;
      } else if (title === 'Settings') {
        return isAdmin(selectedOrgInfo?.role);
      } else if (['Deleted Items', 'Trash Can'].includes(title)) {
        return (
          isAdmin(selectedOrgInfo?.role) ||
          userPermissions.listArchivedResources
        );
      } else if (['Workflows', 'Workflow'].includes(title)) {
        return canAccessWorkflowPage;
      } else if (title === 'ML Testing') {
        return isOrbyAIUser(user);
      } else if (title === 'Integration Test') {
        return user?.email === process.env.REACT_APP_TEST_USER;
      } else if (title === 'Feature Flags') {
        return isOrbyAdmin;
      } else if (title === 'Executions') {
        return canAccessExecutionPage;
      } else if (title === 'Teams New') {
        return isAdmin(selectedOrgInfo?.role) || userPermissions.updateUser;
      } else if (title === 'My Workflows') {
        return canAccessWorkflowPage;
      }
      return true;
    }
  };

  const handleCollapseClicked = () => {
    amplitudeService.trackElementClick({
      element_name: collapse ? 'drawer-expand' : 'drawer-collapse',
      element_type: 'button',
      router_path: routerPath,
      location: window.location.href,
    });
    setCollapse(!collapse);
  };

  return (
    <Box
      bgcolor={OrbyColorPalette['black-0']}
      width={
        collapse ? `${DRAWER_WIDTH_COLLAPSED}px` : `${DRAWER_WIDTH_EXPANDED}px`
      }
      height='100%'
      position={'fixed'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      zIndex={40}
      sx={{
        transition: 'all 0.1s ease',
      }}
    >
      <>
        {renderHeader}
        <SkipLink />
        <Box
          display={'flex'}
          component={'nav'}
          flexDirection={'column'}
          alignItems={'center'}
          maxHeight={'60%'}
          style={{ overflowY: 'auto' }}
          overflow={'hidden'}
          className='drawer-content'
          paddingX={'16px'}
        >
          <Box
            role='tablist'
            aria-label='Tabs navigation'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {DRAWER_TABS.map((tab, index) => {
              if (checkForTabPermissions(tab.title))
                return (
                  <DrawerTab
                    key={index}
                    data={tab}
                    index={index}
                    collapse={collapse}
                    isSelected={tab.route === selectedPath}
                    onClick={handleDrawerItemClick}
                    subTabs={tab.subTabs}
                  />
                );
            })}
          </Box>
        </Box>
        <Tooltip
          title={collapse ? 'Click to expand' : 'Click to collapse'}
          arrow
          placement='right-start'
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [52, 0],
                },
              },
            ],
            sx: {
              '& .MuiTooltip-arrow': { color: OrbyColorPalette['white-0'] },
              '& .MuiTooltip-tooltip': {
                backgroundColor: OrbyColorPalette['white-0'],
                borderRadius: '8px',
                padding: '8px 12px',
                color: OrbyColorPalette['grey-700'],
                fontSize: getFontSize('xs'),
                fontWeight: getFontWeight('semibold'),
                boxShadow:
                  '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
              },
            },
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            flexGrow={1}
            overflow={'hidden'}
            sx={{
              cursor: 'pointer',
            }}
            role='button'
            aria-label={collapse ? 'Click to collapse' : 'Click to expand'}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleCollapseClicked();
              }
            }}
            tabIndex={0}
            onClick={handleCollapseClicked}
          ></Box>
        </Tooltip>
      </>
      <DrawerFooter collapse={collapse} />
    </Box>
  );
};

export default memo(WebDrawerWrapper);
