import { createSelector } from 'reselect';
import { workflowSelector } from './app.selectors';
import { DataLoadingStatus } from '../../utils/constants';

export const workflowListSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflows,
);

export const workflowsLoadingSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.loadingWorkflowsStatus === DataLoadingStatus.LOADING,
);

export const workflowsLoadedSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.loadingWorkflowsStatus === DataLoadingStatus.LOADED,
);

export const workflowsNextPageTokenSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.nextPageToken,
);

export const workflowsTotalSizeSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.totalSize,
);

export const workflowTemplatesListSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowTemplates,
);

export const workflowTemplatesLoadingSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.loadingWorkflowTemplatesStatus === DataLoadingStatus.LOADING,
);

export const workflowTemplatesLoadedSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.loadingWorkflowTemplatesStatus === DataLoadingStatus.LOADED,
);

export const workflowTemplatesNextPageTokenSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowTemplatesNextPageToken,
);

export const workflowTemplatesTotalSizeSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowTemplateTotalSize,
);

export const selectedWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.selectedWorkflow,
);

export const selectedWorkflowLoadingSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.selectedWorkflowLoadingStatus === DataLoadingStatus.LOADING,
);

export const selectedWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.selectedWorkflowLoadingStatus === DataLoadingStatus.ERROR
      ? workflowState.selectedWorkflowError
      : null,
);

export const processWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.processingWorkflowStatus === DataLoadingStatus.ERROR
      ? workflowState.processWorkflowError
      : null,
);

export const processingWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.processingWorkflowStatus === DataLoadingStatus.LOADING,
);

export const createdWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.createdWorkflow,
);

export const updatedWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.updatedWorkflow,
);

export const listWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.listWorkflowError,
);

export const listTemplateWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.loadingWorkflowTemplatesStatus === DataLoadingStatus.ERROR
      ? workflowState.listTemplateWorkflowError
      : null,
);

export const workflowColorsSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowColors,
);

export const workflowFieldListSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.fieldList,
);

export const workflowFieldTotalSizeSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.fieldsTotalSize,
);

export const workflowFieldListLoadingSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.fieldListLoadingStatus === DataLoadingStatus.LOADING,
);

export const workflowFieldListLoadedSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.fieldListLoadingStatus === DataLoadingStatus.LOADED,
);

export const workflowFieldListingErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) =>
    workflowState.fieldListLoadingStatus === DataLoadingStatus.ERROR
      ? workflowState.fieldListingError
      : null,
);
