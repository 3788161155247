import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ELLIPSIS_STYLE } from '../utils/constants';
import { OrbyBadge, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import UserIcon from './UserIcon';

interface Props {
  email: string;
  imageUrl?: string;
  fullName?: string;
  displayEmailExplicitly?: boolean;
  additionalLabelText?: string;
}

const UserCard: React.FC<Props> = ({
  email,
  imageUrl,
  fullName,
  displayEmailExplicitly = false,
  additionalLabelText = '',
}) => {
  const displayName = fullName?.trim() || email;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <UserIcon
        imageUrl={imageUrl}
        fullName={fullName}
        email={email}
        sx={{
          width: 32,
          height: 32,
          fontSize: 20,
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '0px' }}>
        <Box display='flex' gap={1}>
          <Box title={email ?? displayName} sx={{ minWidth: '0px' }}>
            <OrbyTypography
              size='sm'
              weight='regular'
              color={OrbyColorPalette['grey-900']}
              sx={{ ...ELLIPSIS_STYLE }}
            >
              {displayName}
            </OrbyTypography>
          </Box>
          {additionalLabelText && (
            <OrbyBadge
              size='small'
              textColor={OrbyColorPalette['grey-500']}
              backgroundColor={OrbyColorPalette['grey-100']}
              badgeName={additionalLabelText}
            />
          )}
        </Box>
        {displayEmailExplicitly && fullName?.trim() && (
          <Box title={email} sx={{ minWidth: '0px' }}>
            <OrbyTypography
              size='sm'
              weight='regular'
              color={OrbyColorPalette['grey-500']}
              sx={{ ...ELLIPSIS_STYLE }}
            >
              {email}
            </OrbyTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(UserCard);
