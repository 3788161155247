import produce from 'immer';
import { User } from 'protos/pb/v1alpha1/user';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { Workflow as OrbotWorkflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { Reducer } from 'redux';
import { GetFiltersActionType } from '../actions/actions.constants';
import { Filter, DataLoadingStatus } from '../../utils/constants';

export interface FilterOptionsState {
  workflowFilters: Filter[];
  userDetails: User[];
  workflowFiltersError?: Error;
  userFiltersStatus: DataLoadingStatus;
  workflowFiltersStatus: DataLoadingStatus;
  extractionFiltersStatus: DataLoadingStatus;
  classificationFiltersStatus: DataLoadingStatus;

  orbotWorkflowFilters: Filter[];
  orbotWorkflowFiltersError?: Error;
  orbotWorkflowFiltersStatus: DataLoadingStatus;
  userFiltersError?: Error;
}

const initialState: FilterOptionsState = {
  workflowFilters: [],
  userDetails: [],
  userFiltersStatus: DataLoadingStatus.INITIAL,
  workflowFiltersStatus: DataLoadingStatus.INITIAL,
  extractionFiltersStatus: DataLoadingStatus.INITIAL,
  classificationFiltersStatus: DataLoadingStatus.INITIAL,

  orbotWorkflowFilters: [],
  orbotWorkflowFiltersStatus: DataLoadingStatus.INITIAL,
};

export const filterOptionsReducer: Reducer<FilterOptionsState> = (
  state: FilterOptionsState = initialState,
  action: any,
) =>
  produce(state, (draft: FilterOptionsState) => {
    switch (action.type) {
      case GetFiltersActionType.GET_WORKFLOW_FILTERS: {
        draft.workflowFiltersStatus = DataLoadingStatus.LOADING;
        break;
      }
      case GetFiltersActionType.GET_WORKFLOW_FILTERS_COMPLETED: {
        draft.workflowFilters = action.payload.map((w: Workflow) => {
          return { value: w.name, label: w.displayName };
        });
        draft.workflowFiltersStatus = DataLoadingStatus.LOADED;
        break;
      }
      case GetFiltersActionType.GET_WORKFLOW_FILTERS_ERROR: {
        draft.workflowFiltersError = action.payload;
        draft.workflowFiltersStatus = DataLoadingStatus.ERROR;
        break;
      }
      case GetFiltersActionType.GET_USER_FILTERS: {
        draft.userFiltersStatus = DataLoadingStatus.LOADING;
        break;
      }
      case GetFiltersActionType.GET_USER_FILTERS_COMPLETED: {
        draft.userDetails = action.payload ?? [];
        draft.userFiltersStatus = DataLoadingStatus.LOADED;
        break;
      }
      case GetFiltersActionType.GET_USER_FILTERS_ERROR: {
        draft.userFiltersStatus = DataLoadingStatus.ERROR;
        draft.userFiltersError = action.payload;
        break;
      }

      case GetFiltersActionType.GET_ORBOT_WORKFLOW_FILTERS: {
        draft.orbotWorkflowFiltersStatus = DataLoadingStatus.LOADING;
        break;
      }

      case GetFiltersActionType.GET_ORBOT_WORKFLOW_FILTERS_COMPLETED: {
        draft.orbotWorkflowFilters = action.payload
          .map((w: OrbotWorkflow) => {
            return { value: w.id, label: w.displayName };
          })
          .filter((w: Filter) => w.label !== '');
        draft.orbotWorkflowFiltersStatus = DataLoadingStatus.LOADED;
        break;
      }

      case GetFiltersActionType.GET_ORBOT_WORKFLOW_FILTERS_ERROR: {
        draft.orbotWorkflowFiltersError = action.payload;
        draft.orbotWorkflowFiltersStatus = DataLoadingStatus.ERROR;
        break;
      }

      default:
        break;
    }
  });
