import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { Empty } from 'protos/google/protobuf/empty';
import {
  ArchivedResourcesClientImpl,
  ListArchivedResourcesRequest,
  ListArchivedResourcesResponse,
  RestoreArchivedResourcesRequest,
  RestoreDeletedResourcesRequest,
} from 'protos/pb/v1alpha2/archived_resources_service';
import { storageService } from './StorageService';

export class ArchivedResourcesService {
  private static instance: ArchivedResourcesService;

  private static client = new ArchivedResourcesClientImpl(rpcWithErrorHandling);

  public static getInstance(): ArchivedResourcesService {
    if (!this.instance) {
      this.instance = new ArchivedResourcesService();
    }
    return this.instance;
  }

  async listArchivedResources(
    req: ListArchivedResourcesRequest,
  ): Promise<{ response?: ListArchivedResourcesResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await ArchivedResourcesService.client.ListArchivedResources(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async restoreArchivedResources(
    req: RestoreArchivedResourcesRequest,
  ): Promise<{ response?: Empty; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await ArchivedResourcesService.client.RestoreArchivedResources(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error: any) {
      return { error };
    }
  }

  async restoreDeletedResources(
    req: RestoreDeletedResourcesRequest,
  ): Promise<{ response?: Empty; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await ArchivedResourcesService.client.RestoreDeletedResources(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error: any) {
      return { error };
    }
  }
}

export const archivedResourcesService = ArchivedResourcesService.getInstance();
