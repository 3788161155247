import { createSelector } from 'reselect';
import { orbotSelector } from './app.selectors';
import { DataLoadingStatus } from '../../utils/constants';

export const orbotWorkflowListSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.workflows,
);

export const orbotWorkflowsLoadingSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.workflowsStatus === DataLoadingStatus.LOADING,
);

export const orbotWorkflowsLoadedSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.workflowsStatus === DataLoadingStatus.LOADED,
);

export const orbotWorkflowsErrorSelector = createSelector(
  [orbotSelector],
  (orbotState) =>
    orbotState.workflowsStatus === DataLoadingStatus.ERROR
      ? orbotState.listWorkflowError
      : undefined,
);

export const orbotWorkflowsNextPageTokenSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.nextPageToken,
);

export const orbotWorkflowsTotalSizeSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.totalSize,
);

export const listOrbotWorkflowErrorSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.listWorkflowError,
);

export const processOrbotWorkflowErrorSelector = createSelector(
  [orbotSelector],
  (workflowState) => workflowState.processWorkflowError,
);

export const processingOrbotWorkflowSelector = createSelector(
  [orbotSelector],
  (orbotState) =>
    orbotState.processingWorkflowStatus === DataLoadingStatus.LOADING,
);

export const updatedOrbotWorkflowSelector = createSelector(
  [orbotSelector],
  (workflowState) => workflowState.updatedWorkflow,
);

export const cancelExecutionLoadingSelector = createSelector(
  [orbotSelector],
  (orbotState) =>
    orbotState.cancelExecutionStatus === DataLoadingStatus.LOADING,
);

export const cancelExecutionErrorSelector = createSelector(
  [orbotSelector],
  (orbotState) =>
    orbotState.cancelExecutionStatus === DataLoadingStatus.ERROR
      ? orbotState.cancelExecutionError
      : undefined,
);

export const cancelExecutionSuccessSelector = createSelector(
  [orbotSelector],
  (orbotState) => orbotState.cancelExecutionStatus === DataLoadingStatus.LOADED,
);
