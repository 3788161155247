import { GetWorkflowRequest } from 'protos/pb/v1alpha1/orbot_service';
import { WorkflowDetailsType } from './actions.constants';
import {
  Workflow,
  WorkflowActionsForReview,
} from 'protos/pb/v1alpha1/orbot_workflow';
import { WorkflowSecretStoreItem } from '../reducers/workflow_secrets.reducer';
import { Schedule } from 'protos/pb/v1alpha1/schedule';
import {
  GetScheduleRequest,
  ListSchedulesRequest,
  ListSchedulesResponse,
  UpdateScheduleRequest,
} from 'protos/pb/v1alpha1/schedule_service';
import {
  CreateScheduleRequest,
  DeleteScheduleRequest,
} from 'protos/pb/v1alpha1/schedule_service';
import { createAction } from '@reduxjs/toolkit';
import { Action } from 'protos/pb/v1alpha1/orbot_action';

export const setLoading = (payload: boolean) => ({
  type: WorkflowDetailsType.CHANGE_LOADING_STATUS,
  payload,
});

export const setWorkflowSuccess = (payload: Workflow) => ({
  type: WorkflowDetailsType.SET_WORKFLOW_SUCCESS,
  payload,
});

export const getWorkflowFailure = (error: Error) => ({
  type: WorkflowDetailsType.GET_WORKFLOW_FAILURE,
  payload: error,
});

export const fetchWorkflow = (req: GetWorkflowRequest) => ({
  type: WorkflowDetailsType.FETCH_WORKFLOW,
  payload: { req },
});

export const updateWorkflowTitle = (title: string) => ({
  type: WorkflowDetailsType.UPDATE_WORKFLOW_TITLE,
  payload: title,
});

export const setHasUnsavedChanges = (hasUnsavedChanges: boolean) => ({
  type: WorkflowDetailsType.SET_HAS_UNSAVED_CHANGES,
  payload: hasUnsavedChanges,
});

export const addInvalidChange = (field: string) => ({
  type: WorkflowDetailsType.ADD_INVALID_CHANGE,
  payload: field,
});

export const deleteInvalidChange = (field: string) => ({
  type: WorkflowDetailsType.DELETE_INVALID_CHANGE,
  payload: field,
});

export const setActionForReview = (
  actionsForReview: WorkflowActionsForReview[],
) => ({
  type: WorkflowDetailsType.SET_ACTIONS_FOR_REVIEW,
  payload: actionsForReview,
});

export const setLowConfidenceThreshold = (threshold: number) => ({
  type: WorkflowDetailsType.SET_LOW_CONFIDENCE_THRESHOLD,
  payload: threshold,
});

export const addReviewer = (userId: string) => ({
  type: WorkflowDetailsType.ADD_REVIEWER,
  payload: userId,
});

export const removeReviewer = (userId: string) => ({
  type: WorkflowDetailsType.REMOVE_REVIEWER,
  payload: userId,
});

export const addAdmin = (userId: string) => ({
  type: WorkflowDetailsType.ADD_ADMIN,
  payload: userId,
});

export const removeAdmin = (userId: string) => ({
  type: WorkflowDetailsType.REMOVE_ADMIN,
  payload: userId,
});

export const saveWorkflow = (workflow: Workflow, orgId?: string) => ({
  type: WorkflowDetailsType.SAVE_WORKFLOW,
  payload: { workflow, orgId },
});

export const saveCurrentWorkflow = createAction(
  WorkflowDetailsType.SAVE_CURRENT_WORKFLOW,
);

export const addActionAfter = (actionId: string, processId: string) => ({
  type: WorkflowDetailsType.ADD_ACTION_AFTER,
  payload: { actionId, processId },
});

export const removeAction = (actionId: string, processId: string) => ({
  type: WorkflowDetailsType.REMOVE_ACTION,
  payload: { actionId, processId },
});

export const addFalseConditionAction = (
  actionId: string,
  processId: string,
) => ({
  type: WorkflowDetailsType.ADD_FALSE_CONDITION_ACTION,
  payload: { actionId, processId },
});

export const updateAction = (
  action: Action,
  updated: Action,
  processId?: string,
  isTrueAction?: boolean,
) => ({
  type: WorkflowDetailsType.UPDATE_ACTION,
  payload: {
    action,
    updated,
    processId,
    isTrueAction,
  },
});

export const setSchedules = (
  schedules: ListSchedulesResponse,
  reset = false,
) => ({
  type: WorkflowDetailsType.SET_SCHEDULES,
  payload: { schedules, reset },
});

export const getSchedules = (req: ListSchedulesRequest, reset = false) => ({
  type: WorkflowDetailsType.FETCH_SCHEDULES,
  payload: { req, reset },
});

export const getSchedulesError = (error: Error | null) => ({
  type: WorkflowDetailsType.FETCH_SCHEDULES_ERROR,
  payload: error,
});

export const changeSchedulesLoadingStatus = (loading: boolean) => ({
  type: WorkflowDetailsType.CHANGE_SCHEDULES_LOADING_STATUS,
  payload: loading,
});

export const createWorkflowSchedule = (
  req: CreateScheduleRequest,
  refreshResults?: () => void,
) => ({
  type: WorkflowDetailsType.CREATE_SCHEDULE,
  payload: { req, refreshResults },
});

export const createWorkflowScheduleLoading = (payload: boolean) => ({
  type: WorkflowDetailsType.CREATE_UPDATE_SCHEDULE_LOADING,
  payload,
});

export const createUpdateWorkflowScheduleError = (error: Error | null) => ({
  type: WorkflowDetailsType.CREATE_UPDATE_SCHEDULE_ERROR,
  payload: error,
});

/**
 * After a secret block is created, we need to update the workflow with the new secret IDs
 * in the relevant action block.
 *
 * @param {WorkflowSecretStoreItem} wfSecretStoreItem - The secret block we're using to update the workflow
 */
export const updateSecretIDsInWorkflowAction = (
  wfSecretStoreItem: WorkflowSecretStoreItem,
) => ({
  type: WorkflowDetailsType.UPDATE_SECRET_IDS_IN_WORKFLOW,
  payload: { wfSecretStoreItem },
});

export const deleteWorkflowSchedule = (
  req: DeleteScheduleRequest,
  workflowId: string,
  refreshResults?: () => void,
) => ({
  type: WorkflowDetailsType.DELETE_SCHEDULE,
  payload: { req, workflowId, refreshResults },
});

export const deleteWorkflowScheduleError = (error: Error | null) => ({
  type: WorkflowDetailsType.DELETE_SCHEDULE_ERROR,
  payload: error,
});

export const getWorkflowSchedule = (req: GetScheduleRequest) => ({
  type: WorkflowDetailsType.FETCH_SCHEDULE,
  payload: { req },
});

export const setWorkflowSchedule = (schedule: Schedule | null) => ({
  type: WorkflowDetailsType.SET_SCHEDULE,
  payload: schedule,
});

export const updateWorkflowSchedule = (
  req: UpdateScheduleRequest,
  refreshResults?: () => void,
) => ({
  type: WorkflowDetailsType.UPDATE_SCHEDULE,
  payload: { req, refreshResults },
});
