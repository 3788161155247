import { ApplicationName } from './protos/enums';
import { getActionIndex } from './helpers';
import { Workflow, WorkflowMode } from 'protos/pb/v1alpha2/workflows_service';
import {
  parseAssignmentOption,
  parseEntitiesDetails,
  parseLearningSettings,
  parseUsers,
} from './jsonToYamlParser';
import {
  EntityDataType,
  EntityDetails,
  EntityExtractionParam,
  EntityTypeSchema,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import { User } from 'protos/pb/v1alpha1/user';
import { checkIfTypeMatches } from './entities';
import {
  ConditionType,
  LogicalOperator,
  Operator,
  ReviewerList,
} from 'protos/common/review';

export const getYamlActionIndex = (
  jsonObject: any,
  name: ApplicationName,
): {
  stepIndex: number | undefined;
  actionIndex: number | undefined;
} => {
  let stepIndex: number | undefined = undefined;
  let actionIndex = -1;
  jsonObject.steps.map((step: { actions: any[] }, stepIdx: number) => {
    step.actions.map((action, actionIdx: number) => {
      if (action.application && name === action.application) {
        stepIndex = stepIdx;
        actionIndex = actionIdx;
      }
    });
  });
  return {
    stepIndex,
    actionIndex,
  };
};
// Function to add * to annotation entity if not present
export const transformNotesEntityType = (
  normalizationType: string | EntityDataType,
  entityType: string,
): string => {
  // check if entity is of notes type
  const isNotesType = checkIfTypeMatches(
    normalizationType,
    EntityDataType.ENTITY_TYPE_ANNOTATION,
  );
  const needsAsterisk = isNotesType && !entityType?.startsWith('*');

  if (needsAsterisk) {
    return `*${entityType}`;
  }
  return entityType;
};

// This function transforms an array of entity strings into an array of entityDetails objects.
// It uses entityTypeSchemaMapping to fetch normalizationType for each entity.
export const getEntityDetailsFromSchemaMapping = (
  { entities, entityTypeSchemaMapping }: EntityExtractionParam,
  isDownload = false,
  isUpload = false,
) => {
  // Array to store the resulting entityDetails
  const entityDetails: EntityDetails[] = [];
  // Iterating through each entity in the input array
  entities!.forEach((entity) => {
    // Destructuring the result of splitting the entity string into parent and child
    let [parent, child] = entity.split('/');
    if (parent) {
      parent = parent.trim();
    }
    if (child) {
      child = child.trim();
    }
    // Finding an existing parent entity in the entityDetails array
    const parentEntity = entityDetails.find((e) => e.entityType === parent);
    // Checking if there is no existing parent entity
    const normalizationType =
      isDownload || isUpload
        ? entityTypeSchemaMapping![entity]?.normalizationType !== undefined
          ? EntityDataType[entityTypeSchemaMapping![entity].normalizationType!]
          : EntityDataType[EntityDataType.ENTITY_TYPE_TEXT] // reverse mapping
        : entityTypeSchemaMapping![entity]?.normalizationType ||
          EntityDataType.ENTITY_TYPE_TEXT;
    if (!parentEntity) {
      // Get normalization type for parent
      const parentNormalizationType =
        parent && child
          ? isDownload
            ? EntityDataType[EntityDataType.ENTITY_TYPE_NESTED] // reverse mapping op -> ENTITY_TYPE_NESTED
            : EntityDataType.ENTITY_TYPE_NESTED
          : normalizationType;
      // Creating a new entity object for the parent(first part of the split entity)
      // if there both parent and child, setting normalizationType to UNSPECIFIED
      const newEntity: EntityDetails = {
        entityType: transformNotesEntityType(parentNormalizationType, parent),
        normalizationType: parentNormalizationType as EntityDataType,
        properties: [],
      };
      // Checking if there is a child entity
      if (child) {
        // Adding a child entity to the properties array of the new entity
        newEntity.properties!.push({
          entityType: transformNotesEntityType(normalizationType, child),
          normalizationType: normalizationType as EntityDataType,
          properties: [],
        });
      }
      // Adding the new entity to the entityDetails array
      entityDetails.push(newEntity);
    } else if (child) {
      // If there is an existing parent entity, adding the child entity to its properties array
      parentEntity.properties!.push({
        entityType: transformNotesEntityType(normalizationType, child),
        normalizationType: normalizationType as EntityDataType,
        properties: [],
      });
    }
  });
  // Returning the resulting entityDetails array
  return entityDetails;
};

/**
 * This function is used to parse the condition options object
 */
const parseConditionOptions = (reviewerList: ReviewerList) => {
  const conditionType = ConditionType[
    reviewerList.triggerCondition!.conditionType!
  ] as unknown as ConditionType;
  if (conditionType === ConditionType.SPECIFIC_EXTRACTED_FIELD) {
    const conditions =
      reviewerList?.triggerCondition?.conditionOptions?.groupCondition
        ?.conditions || [];
    const groupCondition = {
      conditions: conditions.map((condition) => {
        return {
          attributeType: condition?.attributeType,
          attribute: condition.attribute,
          value: conditions[0].value,
          operator: Operator.LESS_THAN,
        };
      }),
      logicalOperator:
        conditions.length > 1
          ? LogicalOperator.OR
          : LogicalOperator.UNSPECIFIED,
    };
    return { groupCondition };
  } else if (conditionType === ConditionType.RANDOM_SAMPLE_PERCENT) {
    return {
      percentOfRandomSample:
        reviewerList?.triggerCondition?.conditionOptions?.percentOfRandomSample,
    };
  }
  return {
    confidenceScore:
      reviewerList?.triggerCondition?.conditionOptions?.confidenceScore,
  };
};

/**
 * This function is used to parse the reviewer lists object
 */
const parseReviewerLists = (
  reviewerLists: ReviewerList[],
  workflow: Workflow,
  user: User,
) => {
  if (workflow.mode === WorkflowMode.AUTOPILOT) {
    return [
      {
        roundNumber: 1,
        users: [{ user: user.email!.toLowerCase(), enabled: true }],
        triggerCondition: {
          conditionType: ConditionType.UNSPECIFIED,
        },
      },
    ];
  }
  return reviewerLists.map((reviewerList) => {
    const data: Record<string, any> = {
      roundNumber: reviewerList.roundNumber,
      users: parseUsers(reviewerList.users || [], true),
      assignmentOption: parseAssignmentOption(reviewerList.assignmentOption),
    };
    if (reviewerList.triggerCondition) {
      data['triggerCondition'] = {
        conditionType:
          ConditionType[reviewerList.triggerCondition.conditionType!],
        conditionOptions: parseConditionOptions(reviewerList),
      };
    }
    return data;
  });
};

/**
 * This function is used to parse the entity extraction object
 */
const parseEntityExtraction = (entityExtraction: EntityExtractionParam) => {
  const entities: string[] = [];
  const entityTypeSchemaMapping: Record<string, EntityTypeSchema> = {};
  for (const entity in entityExtraction.entityTypeSchemaMapping) {
    entities.push(entity);
    entityTypeSchemaMapping[entity] = {
      normalizationType:
        entityExtraction.entityTypeSchemaMapping[entity].normalizationType,
    };
  }
  const entitiesDetails = !entityExtraction?.entitiesDetails?.length
    ? getEntityDetailsFromSchemaMapping(
        { entities, entityTypeSchemaMapping },
        false,
        true,
      )
    : parseEntitiesDetails(entityExtraction?.entitiesDetails);
  return {
    entitiesDetails,
  };
};

/**
 * This function is used to create a workflow object for the extraction object received from yaml file
 */
export const createSftpExtractionWorkflowObject = (
  workflow: any,
  jsonObject: any,
  user: User,
  isWorkflowCreation = false,
) => {
  if (isWorkflowCreation) {
    // If workflow is being created, set the display name
    workflow.displayName = jsonObject.displayName;
  }
  workflow.mode = WorkflowMode[jsonObject.mode];
  workflow.description = jsonObject.description;
  workflow.learningSettings = parseLearningSettings(
    jsonObject.learningSettings,
    workflow,
    user,
  );
  workflow.manualTimeCostInMinutes = jsonObject.manualTimeCostInMinutes;
  workflow.needAttentionThresholdDefaultMode =
    jsonObject.needAttentionThresholdDefaultMode;
  workflow.reviewerLists = parseReviewerLists(
    jsonObject.reviewerLists,
    workflow,
    user,
  );
  const entityExtractionIndex = getActionIndex(
    workflow,
    ApplicationName.EntityExtraction,
  );
  const yamlEntityExtractionIndex = getYamlActionIndex(
    jsonObject,
    ApplicationName.EntityExtraction,
  );
  if (
    entityExtractionIndex.stepIndex !== undefined &&
    entityExtractionIndex.actionIndex !== undefined &&
    yamlEntityExtractionIndex.stepIndex !== undefined &&
    yamlEntityExtractionIndex.actionIndex !== undefined
  ) {
    workflow.steps[entityExtractionIndex.stepIndex].actions[
      entityExtractionIndex.actionIndex
    ].entityExtraction = parseEntityExtraction(
      jsonObject.steps[yamlEntityExtractionIndex.stepIndex].actions[
        yamlEntityExtractionIndex.actionIndex
      ].entityExtraction,
    );
  }
  return workflow;
};

/**
 * This function is used to create a workflow object for the classification object received from yaml file
 */
export const createSftpClassificationWorkflowObject = (
  workflow: any,
  jsonObject: any,
  user: User,
  isWorkflowCreation = false,
) => {
  if (isWorkflowCreation) {
    // If workflow is being created, set the display name
    workflow.displayName = jsonObject.displayName;
  }
  workflow.mode = WorkflowMode[jsonObject.mode];
  workflow.description = jsonObject.description;
  workflow.learningSettings = parseLearningSettings(
    jsonObject.learningSettings,
    workflow,
    user,
  );
  workflow.manualTimeCostInMinutes = jsonObject.manualTimeCostInMinutes;
  workflow.reviewerLists = parseReviewerLists(
    jsonObject.reviewerLists,
    workflow,
    user,
  );
  const entityClassificationIndex = getActionIndex(
    workflow,
    ApplicationName.DocumentClassification,
  );
  const yamlClassificationIndex = getYamlActionIndex(
    jsonObject,
    ApplicationName.DocumentClassification,
  );
  if (
    entityClassificationIndex.stepIndex !== undefined &&
    entityClassificationIndex.actionIndex !== undefined &&
    yamlClassificationIndex.stepIndex !== undefined &&
    yamlClassificationIndex.actionIndex !== undefined
  ) {
    workflow.steps[entityClassificationIndex.stepIndex].actions[
      entityClassificationIndex.actionIndex
    ].classification =
      jsonObject.steps[yamlClassificationIndex.stepIndex].actions[
        yamlClassificationIndex.actionIndex
      ].classification;
  }
  return workflow;
};
