import { createSelector } from 'reselect';
import { connectorSelector } from './app.selectors';
import { DataLoadingStatus } from '../../utils/constants';

export const connectorListSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.connectors,
);

export const connectorTotalSizeSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.totalSize,
);

export const connectorNextPageTokenSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.nextPageToken,
);

export const connectorListLoadingSelector = createSelector(
  [connectorSelector],
  (connectorState) =>
    connectorState.loadingConnectorsListStatus === DataLoadingStatus.LOADING,
);

export const processConnectorErrorSelector = createSelector(
  [connectorSelector],
  (connectorState) =>
    connectorState.processingConnectorStatus === DataLoadingStatus.ERROR
      ? connectorState.processConnectorError
      : undefined,
);

export const processingConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) =>
    connectorState.processingConnectorStatus === DataLoadingStatus.LOADING,
);

export const createdConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.createdConnector,
);

export const updatedConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.updatedConnector,
);

export const selectedConnectorSelector = createSelector(
  [connectorSelector],
  (connectorState) => connectorState.selectedConnector,
);

export const selectedConnectorLoadingSelector = createSelector(
  [connectorSelector],
  (connectorState) =>
    connectorState.selectedConnectorLoadingStatus === DataLoadingStatus.LOADING,
);

export const selectedConnectorErrorSelector = createSelector(
  [connectorSelector],
  (connectorState) =>
    connectorState.selectedConnectorLoadingStatus === DataLoadingStatus.ERROR
      ? connectorState.selectedConnectorError
      : undefined,
);
