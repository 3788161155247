import React, { memo } from 'react';
import OrbyColorPalette from '../colors/ColorPalette';
import OrbyTypography from '../typography/OrbyTypography';
import { Box } from '@mui/material';

export interface OrbyShortcutKeyProps {
  /**
   * Keyboard key
   */
  children: string;
}

const OrbyShortcutKey: React.FC<OrbyShortcutKeyProps> = ({ children }) => (
  <Box
    sx={{
      border: `1px solid ${OrbyColorPalette['grey-200']}`,
      borderRadius: '4px',
      padding: '5px 8px',
      width: 'max-content',
    }}
  >
    <OrbyTypography color={OrbyColorPalette['grey-700']} weight='medium'>
      {children}
    </OrbyTypography>
  </Box>
);

export default memo(OrbyShortcutKey);
