import React from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export type OrbyToastContainerProps = ToastContainerProps;

const OrbyToastContainer: React.FC<OrbyToastContainerProps> = ({
  ...props
}) => {
  return (
    <ToastContainer
      hideProgressBar
      style={{ width: 'fit-content', maxWidth: '400px', minWidth: '320px' }}
      pauseOnFocusLoss={false}
      {...props}
    />
  );
};

export default React.memo(OrbyToastContainer);
