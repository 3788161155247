import React, { useCallback } from 'react';
import { Box, Tooltip } from '@mui/material';
import { FC, ReactElement, memo, useState } from 'react';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import {
  getFontSize,
  getFontWeight,
} from 'orby-ui/src/components/typography/typography-utils';
import { useNavigate } from 'react-router-dom';

export interface DrawerTabType {
  title: string;
  route: string;
  icon: ReactElement;
  hoverIcon: ReactElement;
  description?: string;
  subTabs?: {
    title: string;
    route: string;
  }[];
}

type DrawerTabProps = {
  index: number;
  data: DrawerTabType;
  isSelected: boolean;
  onClick: (arg: string) => void;
  collapse: boolean;
  subTabs?: {
    title: string;
    route: string;
  }[];
};

const DrawerTab: FC<DrawerTabProps> = ({
  index,
  data,
  isSelected,
  onClick,
  collapse,
  subTabs = [],
}) => {
  const { icon, route, title, description, hoverIcon } = data;

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  // This state is mainly for controlling the tooltip, to hide it when any button on tooltip is clicked
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const subTabsLength = subTabs.length;

  const tooltipTitle = useCallback(() => {
    if (subTabsLength) {
      return (
        <Box
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          sx={{
            padding: '24px 0px 12px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            width: '240px',
          }}
        >
          <Box sx={{ padding: '0 12px' }}>
            <OrbyTypography color={OrbyColorPalette['grey-400']} size='xs'>
              {data?.title}
            </OrbyTypography>
          </Box>

          {subTabs.map((subTab) => (
            <OrbyButton
              key={subTab.title}
              variant='monochrome-text-flat'
              label={subTab.title}
              sx={{
                color: OrbyColorPalette['white-0'],
                padding: '0 12px',
                '&:hover': {
                  backgroundColor: OrbyColorPalette['grey-700'],
                },
                '&:focus': {
                  backgroundColor: OrbyColorPalette['grey-700'],
                },
                justifyContent: 'flex-start',
                width: '100%',
              }}
              onClick={() => {
                navigate({
                  pathname: subTab.route,
                });
                setIsTooltipOpen(false);
              }}
            />
          ))}
        </Box>
      );
    } else {
      return title;
    }
  }, [subTabsLength, title]);

  return (
    <Tooltip
      title={tooltipTitle()}
      arrow
      placement={!subTabsLength ? 'right-end' : 'right'}
      open={subTabsLength ? isTooltipOpen : undefined}
      enterNextDelay={collapse || subTabsLength ? 0 : 1000000}
      enterDelay={collapse || subTabsLength ? 0 : 1000000}
      onClose={() => subTabsLength && setIsTooltipOpen(false)}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: subTabsLength
              ? OrbyColorPalette['grey-900']
              : OrbyColorPalette['white-0'],
            borderRadius: '8px',
            padding: '8px 12px',
            color: OrbyColorPalette['grey-700'],
            fontSize: getFontSize('xs'),
            fontWeight: getFontWeight('semibold'),
            boxShadow:
              '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          },
        },
        arrow: {
          sx: {
            color: subTabsLength
              ? OrbyColorPalette['grey-900']
              : OrbyColorPalette['white-0'],
          },
        },
      }}
    >
      <Box
        key={index}
        padding={'8px 12px'}
        id={route}
        tabIndex={0}
        role={'tab'}
        aria-selected={true}
        display='flex'
        alignItems='center'
        gap={'8px'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!subTabsLength) onClick(route);
        }}
        onKeyUp={(event) => {
          event.stopPropagation();
          if (event.key === 'Enter') {
            if (!subTabsLength) onClick(route);
          }
        }}
        onMouseEnter={() => {
          setIsHovered(true);
          if (subTabsLength) setIsTooltipOpen(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        marginBottom='16px'
        bgcolor={
          isSelected ? 'rgba(255, 255, 255, 0.10)' : OrbyColorPalette['black-0']
        }
        width={'100%'}
        height='40px'
        borderRadius='5px'
        sx={{
          outlineColor: 'red',
          cursor: isSelected ? 'default' : 'pointer',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.10)',
          },
        }}
      >
        <Box
          title={title}
          alignItems={'center'}
          display={'flex'}
          sx={{
            color: OrbyColorPalette['white-0'],
          }}
        >
          {isHovered || isSelected ? hoverIcon : icon}
        </Box>
        <Box
          display={collapse ? 'none' : 'flex'}
          flexDirection='column'
          sx={{
            transition: 'all 0.1s ease',
            opacity: collapse ? 0 : 1,
            pointerEvents: collapse ? 'none' : 'unset',
          }}
        >
          <OrbyTypography
            color={OrbyColorPalette['white-0']}
            sx={{
              textWrap: 'nowrap',
            }}
          >
            {title}
          </OrbyTypography>
          {description && (
            <OrbyTypography
              size='xs'
              color={OrbyColorPalette['grey-500']}
              sx={{
                textWrap: 'nowrap',
              }}
            >
              {description}
            </OrbyTypography>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default memo(DrawerTab);
