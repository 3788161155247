/**
 * This component is used to share workflow to other users,
 * i.e. add/remove users to workflow admin.
 */
import { TextField, Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { FC, memo, useState, useEffect } from 'react';
import CustomTypography from '../../../components/CustomTypography';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import CustomModal from '../../CustomModal';
import AddUserField from '../../../components/AddUserField';
import {
  userDetailsMapSelector,
  userFiltersSelector,
} from '../../../redux/selectors/filter_options.selectors';
import { getUserFiltersAction } from '../../../redux/actions/filter_options.action';
import UserChip from './components/UserChip';
import { WorkflowUser } from 'protos/common/review';

interface ShareWorkflowModalProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  workflow: Workflow;
  onSubmit: (workflowAdmins: string[], message: string) => void;
}

const ShareWorkflowModal: FC<ShareWorkflowModalProps> = ({
  open,
  setOpen,
  workflow,
  onSubmit,
}) => {
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);

  const dispatch = useDispatch();
  const userFilters = useSelector(userFiltersSelector);
  const userDetailsMap = useSelector(userDetailsMapSelector);
  const [userEmailList, setUserEmailList] = useState<string[]>([]);

  useEffect(() => {
    if (userFilters.length) {
      const emails = userFilters.map((f) => f.email);
      setUserEmailList(emails);
    }
  }, [userFilters]);

  useEffect(() => {
    dispatch(getUserFiltersAction(selectedOrgInfo!.orgResourceName!));
  }, [selectedOrgInfo]);

  const formik = useFormik({
    initialValues: {
      workflowAdmins: workflow?.admins || [],
      adminEmailMessage: '',
    },
    onSubmit: (values) => {
      onSubmit(values.workflowAdmins, values.adminEmailMessage);
    },
  });

  const { handleSubmit, values, resetForm, setFieldValue, getFieldProps } =
    formik;

  return (
    <>
      <CustomModal
        open={open}
        containerSx={{
          px: '20px !important',
          maxWidth: '520px',
        }}
        heading={'Edit Workflow Admin'}
        titleSx={{ fontSize: '20px' }}
        headerSx={{ p: '20px', pb: '0px' }}
        actionSx={{ px: '20px', pl: '160px' }}
        primaryButtonSx={{ maxWidth: '70%' }}
        secondaryButtonSx={{ maxWidth: '30%' }}
        closable={true}
        handleClose={() => {
          setOpen(false);
          resetForm();
        }}
        primaryLabel={'Send'}
        secondaryLabel={'Cancel'}
        onPrimaryClick={handleSubmit}
        content={
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                <CustomTypography
                  sx={{
                    marginBottom: '14px',
                    lineHeight: '21px',
                    fontSize: '14px',
                  }}
                >
                  Share this workflow with other workflow admins, they will be
                  able to edit and delete this workflow as well.
                </CustomTypography>
                <Box>
                  <CustomTypography
                    sx={{
                      marginBottom: '6px',
                      lineHeight: '21px',
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Add more workflow admins
                  </CustomTypography>
                  <AddUserField
                    hasError={false}
                    onChange={(email) => {
                      const index = values.workflowAdmins.findIndex(
                        (u) => u == email,
                      );
                      if (index !== -1) {
                        return;
                      }
                      setFieldValue('workflowAdmins', [
                        ...values.workflowAdmins,
                        email,
                      ]);
                    }}
                    searchList={userEmailList}
                    searchValues={values.workflowAdmins.map((email) =>
                      WorkflowUser.create({ user: email, enabled: true }),
                    )}
                  />
                  <Box>
                    {values.workflowAdmins.map((user) => {
                      return (
                        <UserChip
                          key={user}
                          email={user}
                          profileImageUrl={
                            userDetailsMap[user]?.profileImageUrl
                          }
                          isDisabled
                          fullName={userDetailsMap[user]?.fullName}
                          isDeletable={workflow.creator?.username !== user}
                          onDelete={() => {
                            if (workflow.creator?.username === user) {
                              return;
                            }
                            setFieldValue(
                              'workflowAdmins',
                              values.workflowAdmins.filter((u) => u !== user),
                            );
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
                <Box>
                  <CustomTypography
                    sx={{
                      marginTop: '14px',
                      marginBottom: '6px',
                      lineHeight: '21px',
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Message
                  </CustomTypography>
                  <TextField
                    label=''
                    placeholder='Message'
                    multiline
                    fullWidth
                    rows={4}
                    {...getFieldProps('adminEmailMessage')}
                  />
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        }
      />
    </>
  );
};

export default memo(ShareWorkflowModal);
