import { combineReducers } from 'redux';
import {
  AuthActionType,
  ReviewTaskActionType,
} from '../actions/actions.constants';
import { authReducer } from './auth.reducer';
import { suggestionReducer } from './suggestion.reducer';
import { userReducer } from './user.reducer';
import { automationMiningReducer } from './automation_mining.reducer';
import { workflowReducer } from './workflow.reducer';
import { taskV2Reducer } from './taskV2.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { utilityReducer } from './utility.reducer';
import { connectorReducer } from './connector.reducer';
import { reviewTaskReducer } from './review_task.reducer';
import { filterOptionsReducer } from './filter_options.reducer';
import { archivedResourcesReducer } from './archived_resources.reducer';
import { featureFlagsReducer } from './feature_flags.reducer';
import { organizationReducer } from './organization.reducer';
import { orbotReducer } from './orbot.reducer';
import { workflowTaskReducer } from './workflow_task.reducer';
import { workflowDetailsReducer } from './workflow_detail.reducer';
import { workflowSecretReducer } from './workflow_secrets.reducer';
import { undoable } from './undoable.reducer';
import routerReducer from '../slices/router.slice';

const appReducer =
  process.env.NODE_ENV !== 'test'
    ? combineReducers({
        auth: authReducer,
        user: userReducer,
        suggestion: suggestionReducer,
        taskV2: taskV2Reducer,
        automationMining: automationMiningReducer,
        workflow: workflowReducer,
        dashboard: dashboardReducer,
        utility: utilityReducer,
        connector: connectorReducer,
        reviewTask: undoable(reviewTaskReducer, {
          redoAction: ReviewTaskActionType.TASK_REDO,
          undoAction: ReviewTaskActionType.TASK_UNDO,
          clearAction: ReviewTaskActionType.CLEAR_TASK_HISTORY,
          forceSaveHistory: (action) => action?.forceSaveHistory ?? false,
          preserveStateKeys: ['idleSessions', 'reviewStartTime'],
          filter: (action) => {
            // filter function to filter out all the actions for which we don't
            // wish to record the history

            const relatedAction = Object.values(ReviewTaskActionType);

            // If the action belongs to some other reducer ignore the action
            if (!relatedAction.includes(action.type)) {
              return true;
            }

            // Exclude specified actions from history tracking
            // These actions should not trigger history updates due to their specific roles
            const actionsToExclude = [
              ReviewTaskActionType.SET_TOKEN_FOR_HIGHLIGHT,
              ReviewTaskActionType.GET_TASK_FOR_REVIEW,
              ReviewTaskActionType.GET_TASK_FOR_REVIEW_COMPLETED,
              ReviewTaskActionType.SET_SELECTED_ENTITY_IDS_FOR_ANNOTATION,
              ReviewTaskActionType.SET_SELECTED_TEXT_SEGMENT_ID,
              ReviewTaskActionType.SET_TOKEN_LIST_TO_HIGHLIGHT,
              ReviewTaskActionType.LAST_LOCATED_ENTITY_TYPE,
              ReviewTaskActionType.ADD_LOCATION,
              ReviewTaskActionType.SET_SUGGESTION_DATA,
              ReviewTaskActionType.RECORD_IDLE_SESSION,
              ReviewTaskActionType.REVIEW_START_TIME,
              // ORBOT HITL actions
              ReviewTaskActionType.GET_TASK_N_HITL_DATA,
              ReviewTaskActionType.SET_TASK_N_HITL_DATA,
              ReviewTaskActionType.GET_TASK_N_HITL_DATA_ERROR,
              ReviewTaskActionType.UPDATE_REVIEW_TASK,
              ReviewTaskActionType.UPDATE_REVIEW_TASK_COMPLETED,
              ReviewTaskActionType.UPDATE_REVIEW_TASK_ERROR,
              ReviewTaskActionType.UPDATE_MATCHES,
            ];

            if (actionsToExclude.includes(action.type)) {
              return true;
            }

            // Additionally, if an action has the 'excludeFromHistory' flag set, it will be excluded
            if (action?.excludeFromHistory) {
              return true;
            }

            return false;
          },
        }),
        filterOptions: filterOptionsReducer,
        archivedResources: archivedResourcesReducer,
        featureFlags: featureFlagsReducer,
        organization: organizationReducer,
        orbot: orbotReducer,
        workflowTask: workflowTaskReducer,
        workflowDetails: workflowDetailsReducer,
        workflowSecret: workflowSecretReducer,
        router: routerReducer,
      })
    : undefined;

export const rootReducer = (state: any, action: any) => {
  if (process.env.NODE_ENV !== 'test') {
    switch (action.type) {
      case AuthActionType.LOGOUT_COMPLETED:
        return appReducer && appReducer(undefined, action);
      default:
        return appReducer && appReducer(state, action);
    }
  }
};

export type AppState = Exclude<ReturnType<typeof rootReducer>, undefined>;

export default rootReducer;
