import { Box, Checkbox } from '@mui/material';
import React, { FC, memo, useState } from 'react';
import orbyLogo from '../static/orby.svg';
import { UpdateRequest } from 'protos/pb/v1alpha1/users_service';
import { useDispatch, useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  updateUserLoadingSelector,
} from '../redux/selectors/user.selectors';
import { updateUserAction } from '../redux/actions/user.action';
import { User } from 'protos/pb/v1alpha1/user';
import useLogoutHandler from '../hooks/useLogoutHandler';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

const PolicyTermsPage: FC = () => {
  const dispatch = useDispatch();
  const updatingUser = useSelector(updateUserLoadingSelector);
  const [checked, setChecked] = useState(false);
  const user = useSelector(loggedInUserSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const { handleLogout } = useLogoutHandler();
  const handleSecondryClick = () => {
    if (checked) {
      const parsedUser = User.create(user);
      const req = UpdateRequest.create({});
      const u = parsedUser;
      if (u) {
        u.email = parsedUser.email;

        const policies = parsedUser?.prerequisite?.policiesToReview?.map(
          (p) => {
            const policy = p;
            p.acceptedAt = new Date();
            return policy;
          },
        );
        if (u.prerequisite) {
          u.prerequisite.policiesToReview = policies;
        }
        req.user = u;
        if (selectedOrgInfo) {
          req.orgResourceName = selectedOrgInfo.orgResourceName;
        }
        req.fieldMask = ['prerequisite.policies_to_review'];
        dispatch(updateUserAction(req));
      }
    }
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
      bgcolor={OrbyColorPalette['grey-50']}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        paddingTop={'30px'}
        alignItems={'center'}
        height={'808px'}
        width={'900px'}
        bgcolor={OrbyColorPalette['white-0']}
        borderRadius={'20px'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Box>
            <img
              src={orbyLogo}
              width={'150px'}
              style={{ marginBottom: '69.06px' }}
              alt='Orby AI Logo'
            />
          </Box>
          <OrbyTypography
            size='display-xs'
            weight='medium'
            sx={{ marginBottom: '36px' }}
            color={OrbyColorPalette['grey-900']}
          >
            We&apos;ve updated our policies
          </OrbyTypography>
          <OrbyTypography
            size='sm'
            weight='semibold'
            color={OrbyColorPalette['grey-900']}
          >
            Please review and accept the following terms of service to sign in
            to your account.
          </OrbyTypography>
          <Box
            display={'flex'}
            marginTop={'24px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Checkbox
              inputProps={{ 'aria-label': 'Accept Terms' }}
              checked={checked}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setChecked(!checked);
                }
              }}
              onChange={(e) => {
                const checked = e.target.checked;
                setChecked(checked);
              }}
            />
            <OrbyTypography
              size='sm'
              weight='semibold'
              color={OrbyColorPalette['grey-900']}
            >
              I agree to the&nbsp;
            </OrbyTypography>
            {user?.prerequisite?.policiesToReview?.map((p, index) => (
              <Box key={p.policyUrl}>
                <Box component={'a'} href={p.policyUrl} target='_blank'>
                  <OrbyTypography
                    size='sm'
                    weight='semibold'
                    color={OrbyColorPalette['grey-900']}
                    sx={{
                      display: 'inline',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    {p.policyName}
                  </OrbyTypography>
                </Box>
                {index ===
                (user?.prerequisite?.policiesToReview?.length as number) - 2 ? (
                  <OrbyTypography
                    sx={{ display: 'inline', marginX: '4px' }}
                    size='sm'
                    weight='semibold'
                    color={OrbyColorPalette['grey-900']}
                  >
                    and
                  </OrbyTypography>
                ) : index <
                  (user?.prerequisite?.policiesToReview?.length as number) -
                    2 ? (
                  <OrbyTypography
                    sx={{ display: 'inline', marginRight: '4px' }}
                    size='sm'
                    weight='semibold'
                    color={OrbyColorPalette['grey-900']}
                  >
                    ,
                  </OrbyTypography>
                ) : (
                  ''
                )}
              </Box>
            ))}
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            marginTop={'61px'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'16px'}
          >
            <OrbyButton
              label='Continue'
              variant='primary-contained-black'
              size='small'
              onClick={handleSecondryClick}
              disabled={updatingUser || !checked}
              loading={updatingUser}
              sx={{
                width: '150px',
              }}
            />

            <OrbyButton
              label='Cancel'
              variant='primary-outlined-black'
              size='small'
              onClick={handleLogout}
              sx={{
                width: '150px',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PolicyTermsPage);
